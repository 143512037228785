import { BaseModel } from '../../_base/crud/models/_base.model';
import { ExtraCharge } from './extra_charge.model';
import { Area } from './area.model';

export class Shipment extends BaseModel {
  id: number;
  date_created: Date;
  date_approved: Date;
  date_returned: Date;
  recipient_name: string;
  recipient_address: string;
  recipient_area: string;
  recipient_postcode: string;
  recipient_phone: string;
  recipient_phone2: string;
  recipient_service: number;
  weight: number;
  orig_weight: number;
  wcad: number;
  items: number;
  is_cod: boolean;
  cod_ammount: number;
  dec_cod_ammount: number;
  cod_date: Date;
  vg_code: string;
  return_vg: string;
  epitagh_vg: string;
  voucher: string;
  voucher_a6: boolean;
  _status: string;
  user_comments: string;
  user: number;
  is_prepaid: boolean;
  date_paid: Date;
  date_invoiced: Date;
  date_prepaid: Date;
  is_return: boolean;
  sh_comments: string;
  is_ready: boolean;
  prepaid_ammount: number;
  extra_charges: Array<ExtraCharge>;
  vg_code_return: string;
  is_returned: boolean;
  is_delivered: boolean;
  date_delivered: Date;
  date_cancelled: Date;
  is_external: boolean;
  size_x: number;
  size_y: number;
  size_z: number;
  return_delivered: boolean;
  send_delivered: boolean;
  send_pending: boolean;
  is_prereturn: boolean;
  prereturn_ammount: number;
  courier: string;
  spdx_validated_weight: number;
  date_send_delivered: Date;
  date_return_delivered: Date;
  return_of: Shipment;
  return_shipment: Shipment;
  is_dsr: boolean;
  sender_name: string;
  sender_address: string;
  sender_postcode: string;
  sender_area: string;
  sender_phone: string;
  pickup_order: string;
  recipient_selected_area: Area;
  area_charges: any;
  return_vgs: Array<string>;

  clear(): void {
    this.id = undefined;
    this.date_created = undefined;
    this.date_approved = undefined;
    this.date_returned = undefined;
    this.recipient_name = '';
    this.recipient_address = '';
    this.recipient_area = '';
    this.recipient_postcode = '';
    this.recipient_phone = '';
    this.recipient_phone2 = '';
    this.recipient_service = 1;
    this.weight = undefined;
    this.orig_weight = undefined;
    this.wcad = undefined;
    this.items = 1;
    this.is_cod = false;
    this.cod_ammount = undefined;
    this.cod_date = undefined;
    this.vg_code = '';
    this.return_vg = '';
    this.epitagh_vg = '';
    this.voucher = '';
    this.voucher_a6 = false;
    this._status = '';
    this.user_comments = '';
    this.user = undefined;
    this.is_prepaid = false;
    this.date_paid = undefined;
    this.date_invoiced = undefined;
    this.date_prepaid = undefined;
    this.dec_cod_ammount = undefined;
    this.is_return = undefined;
    this.sh_comments = '';
    this.is_ready = undefined;
    this.prepaid_ammount = undefined;
    this.extra_charges = [];
    this.vg_code_return = undefined;
    this.is_returned = false;
    this.is_delivered = false;
    this.date_delivered = undefined;
    this.date_cancelled = undefined;
    this.is_external = undefined;
    this.size_x = undefined;
    this.size_y = undefined;
    this.size_z = undefined;
    this.return_delivered = undefined;
    this.send_delivered = undefined;
    this.send_pending = undefined;
    this.date_send_delivered = undefined;
    this.date_return_delivered = undefined;
    this.recipient_selected_area = undefined;
    this.area_charges = undefined;
    this.return_vgs = [];
  }
}
