// Angular
import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
// RxJS
import { BehaviorSubject, EMPTY, Observable, of, switchMap } from 'rxjs';
import { AuthService, Logout } from '../../../auth';
import { Router } from '@angular/router';
import { catchError, delay, filter, map, take, tap } from 'rxjs/operators';
import { AppState } from '../../../reducers';
import { Store } from '@ngrx/store';
import { AreaPageToggleLoading } from '../../../rex';

@Injectable()
export class InterceptService implements HttpInterceptor {
  isRefreshing = false;
  RefreshLimit = 2;
  refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private auth: AuthService,
    private router: Router,
    private http: HttpClient,
    private store: Store<AppState>,
  ) {}

  // intercept request and add token
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    // eslint-disable-next-line no-debugger
    // modify request
    if (request.headers.get('skip')) {
      return next.handle(request);
    } else {
      return this.refreshTokenIfExpiresSoon().pipe(
        filter((res) => res !== 'wait'),
        take(1),
        delay(0),
        switchMap((res) => {
          if (res === 'cancel') {
            return EMPTY;
          }

          return next.handle(this.addAuthenticatonToken(request));
        }),
      );
    }
  }

  addAuthenticatonToken(request: HttpRequest<any>): HttpRequest<any> {
    const token = localStorage.getItem('accessToken');
    if (token) {
      return request.clone({
        setHeaders: {
          Authorization: `JWT ${token}`,
        },
      });
    } else {
      return request;
    }
  }

  refreshTokenIfExpiresSoon(): Observable<any> {
    const token = localStorage.getItem('accessToken');

    if (this.needsRefresh(token) && !this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshAccessToken()
        .pipe(
          tap((res) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(res);
          }),
          catchError((err) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next('cancel');
            this.logout();
            return of(null);
          }),
        )
        .subscribe();
    }

    if (this.isRefreshing) {
      this.refreshTokenSubject.next('wait');
    } else {
      this.refreshTokenSubject.next('run');
    }

    return this.refreshTokenSubject;
  }

  refreshAccessToken(): Observable<any> {
    const refs_token = localStorage.getItem('refreshToken');

    if (!refs_token) {
      this.logout();
      return of(null);
    }

    //need to check for bad return
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      skip: 'true',
    });
    return this.http
      .post<any>(
        'api/api-token-refresh/',
        { refresh: refs_token },
        { headers: httpHeaders },
      )
      .pipe(
        map((res: any) => {
          localStorage.setItem('accessToken', res.access);
          return of(res.access);
        }),
        catchError((err) => {
          this.logout();
          return of(null);
        }),
      );
  }

  logout() {
    this.store.dispatch(new Logout());
    //TODO: need to remove loading on logout or better yet centralize loading on this interceptor
  }

  parseJwt(token: any) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  }

  needsRefresh(token: string | null): boolean {
    if (!token) {
      return false;
    }

    const exp = new Date(0);
    exp.setUTCSeconds(this.parseJwt(token).exp);
    const now = new Date(Date.now());
    const diff = exp.getTime() - now.getTime();
    const minsLeft = Math.round(((diff % 86400000) % 3600000) / 60000);
    return minsLeft <= this.RefreshLimit;
  }
}
