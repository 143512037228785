// Angular
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
// RxJS
import { Observable, of, Subject, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { Actions, ofType } from '@ngrx/effects';
// Layout
import {
  LayoutConfigService,
  SubheaderService,
} from '../../../../../core/_base/layout';
import {
  LayoutUtilsService,
  MessageType,
} from '../../../../../core/_base/crud';
import {
  AuthService,
  selectUsersInStore,
  User,
} from '../../../../../core/auth';
import { orderBy } from 'lodash';

// Services and Models
import {
  ActionFail,
  ActionSuccess,
  ManualReturnCreate,
  selectShipmentsActionLoading,
  Shipment,
  ShipmentActionTypes,
} from '../../../../../core/rex';

import createNumberMask from 'text-mask-addons/dist/createNumberMask';

export function weightValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    //const value = control.value.replace(' Kg','').replace(',','');
    const k = '' + control.value;
    const value = parseFloat(k.replace(' Kg', '').replace(',', ''));

    if (!control.value) {
      return null;
    }

    return value > 0 ? null : { weightOK: false };
  };
}

@Component({
  selector: 'kt-create-return',
  templateUrl: './create-return.component.html',
  styleUrls: ['./create-return.component.scss'],
})
export class CreateReturnComponent implements OnInit, OnDestroy {
  shipment: Shipment;
  selectedTab = 0;
  loading$: Observable<boolean>;
  shipmentForm: UntypedFormGroup;
  hasFormErrors = false;
  serverError: Subject<any>;

  kg_mask = createNumberMask({
    suffix: ' Kg',
    prefix: '',
    allowDecimal: true,
    decimalLimit: 2,
    //requireDecimal: true
  });
  cm_mask = createNumberMask({
    suffix: ' cm',
    prefix: '',
    allowDecimal: true,
    decimalLimit: 2,
    //requireDecimal: true
  });

  adminView = false;
  filteredOptions: Observable<User[]>;
  shipmentUser: User;

  private subscriptions: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private shipmentFB: UntypedFormBuilder,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>,
    private layoutConfigService: LayoutConfigService,
    private actions$: Actions,
    private auth: AuthService,
  ) {}

  ngOnInit() {
    this.loading$ = this.store.pipe(select(selectShipmentsActionLoading));
    this.serverError = new Subject();

    if (this.router.url.startsWith('/shipment-management/admin')) {
      this.adminView = true;
    }

    this.shipment = new Shipment();
    this.shipment.clear();
    this.initShipment();

    const ss = this.actions$
      .pipe(
        ofType<ActionSuccess>(ShipmentActionTypes.ActionSuccess),
        tap(({ payload }) => {
          this.layoutUtilsService.showActionNotification(
            payload.result,
            MessageType.Update,
            5000,
            true,
            true,
          );
          this.goBackWithId();
        }),
      )
      .subscribe();
    this.subscriptions.push(ss);

    const sf = this.actions$
      .pipe(
        ofType<ActionFail>(ShipmentActionTypes.ActionFail),
        tap(({ payload }) => {
          if (
            // eslint-disable-next-line no-prototype-builtins
            payload.result.hasOwnProperty('error') &&
            // eslint-disable-next-line no-prototype-builtins
            payload.result.error.hasOwnProperty('detail')
          ) {
            this.serverError.next(payload.result.error.detail);
          } else {
            this.serverError.next(
              'Συνέβη ένα σφάλμα με την αποθήκευση των δεδομένων. Παρακαλώ ξαναπροσπαθήστε.',
            );
          }
        }),
      )
      .subscribe();
    this.subscriptions.push(sf);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  initShipment() {
    this.createForm();
    this.subheaderService.setTitle('Δημιουργία Αποστολής');
    if (this.adminView) {
      this.subheaderService.setBreadcrumbs([
        {
          title: 'Διαχείριση Αποστολών',
          page: `shipment-management/admin/shipments`,
        },
        { title: 'Αποστολές', page: `shipment-management/admin/shipments` },
        {
          title: 'Δημιουργία Επιστροφής',
          page: `shipment-management/admin/shipments/create-return`,
        },
      ]);
    } else {
      this.subheaderService.setBreadcrumbs([
        { title: 'Διαχείριση Αποστολών', page: `shipment-management` },
        { title: 'Αποστολές', page: `shipment-management/shipments` },
        {
          title: 'Δημιουργία Επιστροφής',
          page: `shipment-management/shipments/create-return`,
        },
      ]);
    }
  }

  createForm() {
    let fgd: { [k: string]: any };

    // eslint-disable-next-line prefer-const
    fgd = {
      sender_name: [this.shipment.sender_name, Validators.required],
      sender_address: [this.shipment.sender_address, Validators.required],
      sender_postcode: [this.shipment.sender_postcode, Validators.required],
      sender_area: [this.shipment.sender_area, Validators.required],
      sender_phone: [
        this.shipment.sender_phone,
        [Validators.required, Validators.pattern('^[0-9]*$')],
      ],
      weight: [this.shipment.weight, [Validators.required, weightValidator()]],
      items: [
        this.shipment.items,
        [
          Validators.min(1),
          Validators.max(15),
          Validators.required,
          Validators.pattern('^[0-9]*$'),
        ],
      ],
      user_comments: [this.shipment.user_comments],
      sh_comments: [this.shipment.sh_comments],
      size_x: [this.shipment.size_x],
      size_y: [this.shipment.size_y],
      size_z: [this.shipment.size_z],
    };

    if (this.adminView) {
      fgd.user = [this.shipmentUser, Validators.required];
    }

    this.shipmentForm = this.shipmentFB.group(fgd);

    if (this.adminView) {
      this.filteredOptions = this.shipmentForm.get('user').valueChanges.pipe(
        startWith(undefined),
        debounceTime(400),
        distinctUntilChanged(),
        switchMap((val) => this._filter(val || '')),
      );
    }
  }

  _filter(val: any): Observable<User[]> {
    if (typeof val === 'object') {
      return of(null);
    } else {
      return this.store.pipe(
        select(selectUsersInStore),
        map((res) => res.items),
        map((res: Array<User>) => res.filter((usr: User) => usr.id != 1)),
        map((res: Array<User>) =>
          res.filter(
            (usr: User) =>
              usr.id == val ||
              usr.kap == val ||
              usr.firstname.match(new RegExp(val, 'i')) ||
              usr.lastname.match(new RegExp(val, 'i')) ||
              usr.email.match(new RegExp(val, 'i')),
          ),
        ),
        //take(1),
        map((res) => orderBy(res, ['id'], ['asc'])),
      );
    }
  }

  displayFn(user?: User): string | undefined {
    return user ? `${user.lastname} ${user.firstname}` : undefined;
  }

  goBackWithId() {
    let url = `/shipment-management/shipments`;
    if (this.adminView) {
      url = `/shipment-management/admin/shipments`;
    }
    this.router.navigate([url], { relativeTo: this.activatedRoute });
  }

  reset() {
    this.shipment = Object.assign({}, new Shipment());
    this.serverError.next(false);
    this.createForm();
    this.hasFormErrors = false;
    this.shipmentForm.markAsPristine();
    this.shipmentForm.markAsUntouched();
    this.shipmentForm.updateValueAndValidity();
  }

  onSumbit(withBack = false, addNew = false) {
    this.hasFormErrors = false;
    this.serverError.next(false);
    const controls = this.shipmentForm.controls;

    if (this.shipmentForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched(),
      );

      this.hasFormErrors = true;
      return;
    }

    const editedShipment = this.prepareShipment();

    this.addReturn(editedShipment);
  }

  prepareShipment(): Shipment {
    const controls = this.shipmentForm.controls;
    const _shipment = new Shipment();
    _shipment.sender_name = controls.sender_name.value;
    _shipment.sender_address = controls.sender_address.value;
    _shipment.sender_area = controls.sender_area.value;
    _shipment.sender_postcode = controls.sender_postcode.value;
    _shipment.sender_phone = controls.sender_phone.value;
    _shipment.weight = controls.weight.value
      .replace(' Kg', '')
      .replace(',', '');
    _shipment.items = controls.items.value;
    _shipment.is_cod = false;
    _shipment.cod_ammount = null;
    _shipment.user_comments = controls.user_comments.value;
    _shipment.sh_comments = controls.sh_comments.value;

    if (controls.size_x.value) {
      _shipment.size_x = controls.size_x.value
        .replace(' cm', '')
        .replace(',', '');
    } else {
      _shipment.size_x = null;
    }

    if (controls.size_y.value) {
      _shipment.size_y = controls.size_y.value
        .replace(' cm', '')
        .replace(',', '');
    } else {
      _shipment.size_y = null;
    }

    if (controls.size_z.value) {
      _shipment.size_z = controls.size_z.value
        .replace(' cm', '')
        .replace(',', '');
    } else {
      _shipment.size_z = null;
    }

    if (
      controls.size_z.value &&
      controls.size_y.value &&
      controls.size_x.value
    ) {
      let calced_weight =
        (parseFloat(controls.size_x.value.replace(' cm', '').replace(',', '')) *
          parseFloat(
            controls.size_y.value.replace(' cm', '').replace(',', ''),
          ) *
          parseFloat(
            controls.size_z.value.replace(' cm', '').replace(',', ''),
          )) /
        5000;
      calced_weight = parseFloat(calced_weight.toFixed(2));
      if (calced_weight > parseFloat('' + _shipment.weight)) {
        _shipment.weight = calced_weight;
      }
    }

    if (this.adminView) {
      if (controls.user.value) {
        _shipment.user = controls.user.value.id;
      }
    }

    return _shipment;
  }

  addReturn(_shipment: Shipment) {
    // This should save shipment and upon success navigate back
    this.store.dispatch(new ManualReturnCreate({ shipment: _shipment }));
    //console.log(_shipment);
    //this.router.navigateByUrl(`/shipment-management/admin/shipments`, { relativeTo: this.activatedRoute });
  }

  getComponentTitle() {
    const result = 'Δημιουργία Επιστροφής';
    return result;
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
    this.serverError.next(false);
  }
}
