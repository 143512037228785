// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// NGRX
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
// Translate
import { TranslateModule } from '@ngx-translate/core';
import { PartialsModule } from '../../partials/partials.module';
// Services
import {
  HttpUtilsService,
  TypesUtilsService,
  InterceptService,
  LayoutUtilsService,
} from '../../../core/_base/crud';
// Shared
// Components
import { UsrMngtComponent } from './usr-mngt.component';
import { UsersListComponent } from './users/users-list/users-list.component';

// Material
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  MatLegacyDialogModule as MatDialogModule,
  MAT_LEGACY_DIALOG_DEFAULT_OPTIONS as MAT_DIALOG_DEFAULT_OPTIONS,
} from '@angular/material/legacy-dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacySliderModule as MatSliderModule } from '@angular/material/legacy-slider';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatLegacyTableModule as MatTableModule } from '@angular/material/legacy-table';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
//import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatTooltipModule } from '@angular/material/tooltip';
import { usersReducer, UserEffects } from '../../../core/auth';
import {
  userfileReducer,
  UserfileEffects,
  userwfdReducer,
  UserwfdEffects,
} from '../../../core/rex';

import { UserEditComponent } from './users/user-edit/user-edit.component';
import { AddressComponent } from './users/_subs/address/address.component';
import { ChangePasswordComponent } from './users/_subs/change-password/change-password.component';
import { StaffGuard, RestrictedGuard } from '../../../core/auth';
import { CompanyComponent } from './users/_subs/company/company.component';

import { ngfModule } from 'angular-file';
import { UsrFilesComponent } from './users/_subs/usr-files/usr-files.component';
import { FbytesPipe } from './users/_pipes/fbytes.pipe';
import { ChargesComponent } from './users/_subs/charges/charges.component';
import { PriceRangeComponent } from './users/_subs/charges/price-range/price-range.component';

import { CurrencyMaskModule } from 'ng2-currency-mask';
import { TextMaskModule } from 'angular2-text-mask';
import { UserwfdComponent } from './users/userwfd/userwfd.component';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AggrFinComponent } from './users/_subs/aggr-fin/aggr-fin.component';
import { NgApexchartsModule } from 'ng-apexcharts';

import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { ApiKeysComponent } from './users/_subs/api-keys/api-keys.component';
import { CschargesComponent } from './users/_subs/cscharges/cscharges.component';

export const MY_FORMATS = {
  parse: {
    dateInput: ['L', 'LL'],
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const routes: Routes = [
  {
    path: '',
    component: UsrMngtComponent,
    children: [
      {
        path: '',
        redirectTo: 'users',
        pathMatch: 'full',
      },
      {
        path: 'users',
        component: UsersListComponent,
        canActivate: [StaffGuard],
      },
      {
        path: 'users:id',
        component: UsersListComponent,
        canActivate: [StaffGuard],
      },
      {
        path: 'users/add',
        component: UserEditComponent,
        canActivate: [StaffGuard],
      },
      {
        path: 'users/add:id',
        component: UserEditComponent,
        canActivate: [StaffGuard],
      },
      {
        path: 'users/edit',
        component: UserEditComponent,
        canActivate: [StaffGuard],
      },
      {
        path: 'users/edit/:id',
        component: UserEditComponent,
        canActivate: [StaffGuard],
      },
      {
        path: 'users/financial',
        component: UserwfdComponent,
        canActivate: [StaffGuard],
      },
      {
        path: 'profile',
        component: UserEditComponent,
        canActivate: [RestrictedGuard],
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    PartialsModule,
    RouterModule.forChild(routes),
    StoreModule.forFeature('users', usersReducer),
    EffectsModule.forFeature([UserEffects]),
    StoreModule.forFeature('userfiles', userfileReducer),
    EffectsModule.forFeature([UserfileEffects]),
    StoreModule.forFeature('userwfds', userwfdReducer),
    EffectsModule.forFeature([UserwfdEffects]),
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    MatButtonModule,
    MatSliderModule,
    MatMenuModule,
    MatSelectModule,
    MatInputModule,
    MatTableModule,
    MatAutocompleteModule,
    MatRadioModule,
    MatIconModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCardModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatTabsModule,
    MatTooltipModule,
    MatDialogModule,
    MatSlideToggleModule,
    CurrencyMaskModule,
    TextMaskModule,
    MatChipsModule,
    PerfectScrollbarModule,
    ngfModule,
    NgApexchartsModule,
  ],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        panelClass: 'kt-mat-dialog-container__wrapper',
        height: 'auto',
        width: '900px',
      },
    },
    { provide: MAT_DATE_LOCALE, useValue: 'el-GR' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService,
  ],
  declarations: [
    UsrMngtComponent,
    UsersListComponent,
    UserEditComponent,
    AddressComponent,
    ChangePasswordComponent,
    CompanyComponent,
    UsrFilesComponent,
    FbytesPipe,
    ChargesComponent,
    PriceRangeComponent,
    UserwfdComponent,
    AggrFinComponent,
    ApiKeysComponent,
    CschargesComponent,
  ],
})
export class UsrMngtModule {}
