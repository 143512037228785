// Angular
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// RxJS
import {
  catchError,
  filter,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { defer, interval, Observable, of } from 'rxjs';
// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
// Auth actions
import {
  AuthActionTypes,
  Login,
  Logout,
  Register,
  UserLoaded,
  UserRefreshed,
  UserRequested,
} from '../_actions/auth.actions';
import { AllUsersRequested, FetchUser } from '../_actions/user.actions';
import {
  GConfigRequested,
  LoadAllCharges,
  LoadAllCSCharges,
  Notification,
  NotificationCreateIfNotExists,
  NotificationDelete,
  NotificationsRequested,
  selectNotificationsInStore,
} from '../../rex/';
import { AuthService } from '../_services/index';
import { AppState } from '../../reducers';
import { environment } from '../../../../environments/environment';
import { isUserLoaded } from '../_selectors/auth.selectors';

@Injectable()
export class AuthEffects {
  login$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Login>(AuthActionTypes.Login),
        tap((action) => {
          localStorage.setItem(
            environment.authTokenKey,
            action.payload.authToken,
          );
          localStorage.setItem('refreshToken', action.payload.refreshToken);
          localStorage.setItem('accessToken', action.payload.authToken);
          this.store.dispatch(new UserRequested());
        }),
      ),
    { dispatch: false },
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<Logout>(AuthActionTypes.Logout),
        tap(() => {
          localStorage.removeItem(environment.authTokenKey);
          localStorage.removeItem('accessToken');
          localStorage.removeItem('refreshToken');
          this.router.navigate(['/auth/login']);
          this.store.dispatch(new GConfigRequested());
        }),
      ),
    { dispatch: false },
  );

  register$ = createEffect(() =>
    this.actions$.pipe(
      ofType<Register>(AuthActionTypes.Register),
      tap((action) => {
        localStorage.setItem(
          environment.authTokenKey,
          action.payload.authToken,
        );
      }),
    ),
  );

  refreshUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UserRefreshed>(AuthActionTypes.UserRefreshed),
        mergeMap((action) => {
          return this.auth.getUserByToken().pipe(
            catchError((err) => {
              this.store.dispatch(new Logout());
              return of(null);
            }),
          );
        }),
        tap((_user) => {
          if (_user) {
            this.store.dispatch(new UserLoaded({ user: _user }));
          }
        }),
        withLatestFrom(this.store.pipe(select(selectNotificationsInStore))),
        tap(([res, nfs]) => {
          if (res) {
            if (res.is_company) {
              if (
                !res.companyName ||
                (!res.phone && !res.mobile) ||
                !res.companyVat ||
                !res.companyFiscalService ||
                !res.companyAddress ||
                !res.companyPostCode ||
                !res.companyCity
              ) {
                const k: Notification = new Notification();
                k.defaults();
                k._text = 'Το προφίλ σας δεν είναι συμπληρωμένο';
                k._icon = 'flaticon2-user';
                k._type = 'alert';
                k._ctype = 'kt-font-danger';
                k._router_link = '/user-management/profile';

                this.store.dispatch(
                  new NotificationCreateIfNotExists({ notification: k }),
                );
              } else {
                const n: Notification = nfs.find(
                  (el: Notification) =>
                    el._text === 'Το προφίλ σας δεν είναι συμπληρωμένο' &&
                    el._type === 'alert',
                );
                if (n) {
                  this.store.dispatch(new NotificationDelete({ id: n.id }));
                }
              }
            } else {
              if (
                !res.user_address ||
                (!res.phone && !res.mobile) ||
                (!res.vat && !res.adt) ||
                !res.city ||
                !res.post_code
              ) {
                const k: Notification = new Notification();
                k.defaults();
                k._text = 'Το προφίλ σας δεν είναι συμπληρωμένο';
                k._icon = 'flaticon2-user';
                k._type = 'alert';
                k._ctype = 'kt-font-danger';
                k._router_link = '/user-management/profile';

                this.store.dispatch(
                  new NotificationCreateIfNotExists({ notification: k }),
                );
              } else {
                const n: Notification = nfs.find(
                  (el: Notification) =>
                    el._text === 'Το προφίλ σας δεν είναι συμπληρωμένο' &&
                    el._type === 'alert',
                );
                if (n) {
                  this.store.dispatch(new NotificationDelete({ id: n.id }));
                }
              }
            }
            this.store.dispatch(new NotificationsRequested());
            this.store.dispatch(new GConfigRequested());
          }
        }),
      ),
    { dispatch: false },
  );

  loadUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<UserRequested>(AuthActionTypes.UserRequested),
        withLatestFrom(this.store.pipe(select(isUserLoaded))),
        filter(([action, _isUserLoaded]) => !_isUserLoaded),
        mergeMap(([action, _isUserLoaded]) => {
          return this.auth.getUserByToken().pipe(
            catchError((err) => {
              this.store.dispatch(new Logout());
              return of(null);
            }),
          );
        }),
        tap((_user) => {
          if (_user) {
            this.store.dispatch(new UserLoaded({ user: _user }));
            this.store.dispatch(new NotificationsRequested());

            interval(3000 * 60).subscribe((x) =>
              this.store.dispatch(new NotificationsRequested()),
            );

            this.store.dispatch(new GConfigRequested());
          } else {
            this.store.dispatch(new Logout());
          }
        }),
        tap((res) => {
          if (res) {
            this.store.dispatch(new FetchUser({ id: res.id }));
            if (res.is_staff) {
              this.store.dispatch(new AllUsersRequested());
            }
          }
        }),
        tap((res) => this.store.dispatch(new LoadAllCharges())),
        tap((res) => this.store.dispatch(new LoadAllCSCharges())),
        tap((res) => {
          if (res) {
            if (res.is_company) {
              if (
                !res.companyName ||
                (!res.phone && !res.mobile) ||
                !res.companyVat ||
                !res.companyFiscalService ||
                !res.companyAddress ||
                !res.companyPostCode ||
                !res.companyCity
              ) {
                const k: Notification = new Notification();
                k.defaults();
                k._text = 'Το προφίλ σας δεν είναι συμπληρωμένο';
                k._icon = 'flaticon2-user';
                k._type = 'alert';
                k._ctype = 'kt-font-danger';
                k._router_link = '/user-management/profile';

                this.store.dispatch(
                  new NotificationCreateIfNotExists({ notification: k }),
                );
              }
            } else {
              if (
                !res.user_address ||
                (!res.phone && !res.mobile) ||
                (!res.vat && !res.adt) ||
                !res.city ||
                !res.post_code
              ) {
                const k: Notification = new Notification();
                k.defaults();
                k._text = 'Το προφίλ σας δεν είναι συμπληρωμένο';
                k._icon = 'flaticon2-user';
                k._type = 'alert';
                k._ctype = 'kt-font-danger';
                k._router_link = '/user-management/profile';

                this.store.dispatch(
                  new NotificationCreateIfNotExists({ notification: k }),
                );
              }
            }
          }
        }),
      ),
    { dispatch: false },
  );

  init$: Observable<Action> = createEffect(() =>
    defer(() => {
      const userToken = localStorage.getItem(environment.authTokenKey);
      const refreshToken = localStorage.getItem('refreshToken');
      let observableResult = of({ type: 'NO_ACTION' });
      if (userToken) {
        observableResult = of(
          new Login({ authToken: userToken, refreshToken: refreshToken }),
        );
      }
      return observableResult;
    }),
  );

  private returnUrl: string;

  constructor(
    private actions$: Actions,
    private router: Router,
    private auth: AuthService,
    private store: Store<AppState>,
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.returnUrl = event.url;
      }
    });
  }
}
