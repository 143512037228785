// Angular
import {
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewEncapsulation,
} from '@angular/core';
// Layout
import {
  LayoutConfigService,
  SplashScreenService,
  TranslationService,
} from '../../../core/_base/layout';
// Auth
import { AuthNoticeService } from '../../../core/auth';

import { LayoutUtilsService } from '../../../core/_base/crud';
// RxJS
import { Subject, Observable, of, Subscription } from 'rxjs';
import { take, map, tap, delay } from 'rxjs/operators';

import { selectGConfigById } from '../../../core/rex';
import { Store, select } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
// AppState
import { AppState } from '../../../core/reducers';

@Component({
  selector: 'kt-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AuthComponent implements OnInit {
  // Public properties
  today: number = Date.now();
  headerLogo: string;

  /**
   * Component constructor
   *
   * @param el
   * @param render
   * @param layoutConfigService: LayoutConfigService
   * @param authNoticeService: authNoticeService
   * @param translationService: TranslationService
   * @param splashScreenService: SplashScreenService
   */
  constructor(
    private el: ElementRef,
    private render: Renderer2,
    private layoutConfigService: LayoutConfigService,
    public authNoticeService: AuthNoticeService,
    private translationService: TranslationService,
    private layoutUtilsService: LayoutUtilsService,
    private store: Store<AppState>,
    private splashScreenService: SplashScreenService,
  ) {}

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.translationService.setLanguage(
      this.translationService.getSelectedLanguage(),
    );
    this.headerLogo = this.layoutConfigService.getLogo();

    this.splashScreenService.hide();
  }

  /**
   * Load CSS for this specific page only, and destroy when navigate away
   * @param styleUrl
   */
  private loadCSS(styleUrl: string) {
    return new Promise((resolve, reject) => {
      const styleElement = document.createElement('link');
      styleElement.href = styleUrl;
      styleElement.type = 'text/css';
      styleElement.rel = 'stylesheet';
      styleElement.onload = resolve;
      this.render.appendChild(this.el.nativeElement, styleElement);
    });
  }

  ox() {
    const dt: { [key: string]: any } = {};
    dt['title'] = 'Όροι Χρήσης';
    dt['icon'] = 'fas fa-shield-alt';
    this.store
      .pipe(
        select(selectGConfigById('general__ox')),
        take(1),
        tap((res) => (dt['content'] = res.value)),
      )
      .subscribe();
    this.layoutUtilsService.viewInfo(dt);
  }

  gdpr() {
    const dt: { [key: string]: any } = {};
    dt['title'] = 'GDPR';
    dt['icon'] = 'fas fa-user-shield';
    this.store
      .pipe(
        select(selectGConfigById('general__gdpr')),
        take(1),
        tap((res) => (dt['content'] = res.value)),
      )
      .subscribe();
    this.layoutUtilsService.viewInfo(dt);
  }

  contact() {
    const dt: { [key: string]: any } = {};
    dt['title'] = 'Επικοινωνία';
    dt['icon'] = 'fas fa-address-book';
    this.store
      .pipe(
        select(selectGConfigById('general__contact')),
        take(1),
        tap((res) => (dt['content'] = res.value)),
      )
      .subscribe();
    this.layoutUtilsService.viewInfo(dt);
  }
}
