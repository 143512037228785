// Angular
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// Material
import { SelectionModel } from '@angular/cdk/collections';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
// RXJS
import {
  catchError,
  debounceTime,
  delay,
  distinctUntilChanged,
  finalize,
  last,
  map,
  skip,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';
import {
  BehaviorSubject,
  fromEvent,
  merge,
  Observable,
  of,
  Subject,
  Subscription,
} from 'rxjs';
// LODASH
import { isEmpty, orderBy } from 'lodash';
// NGRX
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../../core/reducers';
import { Actions, ofType } from '@ngrx/effects';
import * as moment from 'moment';
// Services
import {
  LayoutUtilsService,
  MessageType,
  QueryParamsModel,
} from '../../../../../core/_base/crud';

// Models and selectors
import {
  ActionFail,
  ActionRefresh,
  ActionSuccess,
  AddByXLS,
  CreateAutoReturn,
  FetchVoucher,
  GotVoucher,
  MAError,
  MarkPending,
  MarkReady,
  MassMarkReady,
  MassUnMarkReady,
  selectListFilters,
  selectShipmentById,
  selectUserFilters,
  SetListFilters,
  SetUserFilters,
  Shipment,
  ShipmentActionTypes,
  ShipmentApprove,
  ShipmentDelete,
  ShipmentMassApprove,
  ShipmentMassDelete,
  ShipmentsDataSource,
  ShipmentsPageRequested,
  UnMarkReady,
} from '../../../../../core/rex';
import {
  AllUsersLoaded,
  AllUsersRequested,
  AuthService,
  currentUser,
  selectUserById,
  selectUsersInStore,
  User,
  UserActionTypes,
} from '../../../../../core/auth';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SubheaderService } from '../../../../../core/_base/layout';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { UntypedFormControl } from '@angular/forms';
import {
  MatLegacyAutocomplete as MatAutocomplete,
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
} from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { MatButtonToggle } from '@angular/material/button-toggle';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';
//import * as XLSX from 'xlsx';
//import { WorkBook } from 'xlsx';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'kt-shipments-list',
  templateUrl: './shipments-list.component.html',
  styleUrls: ['./shipments-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentsListComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('pdfViewerOnDemand') pdfViewerOnDemand;

  dataSource: ShipmentsDataSource;
  displayedColumns = [
    'select',
    'recipient_name',
    'date_created',
    'date_approved',
    'cod_ammount',
    '_status',
    'vg_code',
    'is_ready',
    'is_delivered',
    'actions',
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('sort1', { static: true }) sort: MatSort;
  // Filter fields
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  @ViewChild('searchInputTel', { static: true }) searchInputTel: ElementRef;
  @ViewChild('codtoggle', { static: true }) codToggle: MatButtonToggle;
  @ViewChild('searchInputFA', { static: true }) searchInputFA: ElementRef;
  @ViewChild('searchInputTA', { static: true }) searchInputTA: ElementRef;

  fFAp: number;
  fTAp: number;

  lastQuery: QueryParamsModel;
  // Selection
  selection = new SelectionModel<Shipment>(true, []);
  shipmentsResult: Shipment[] = [];
  adminView = false;

  // Subscriptions
  private subscriptions: Subscription[] = [];

  serverError: Subject<any>;
  isPrinting: BehaviorSubject<boolean>;

  curr_mask = createNumberMask({
    prefix: '€ ',
    allowDecimal: true,
    decimalLimit: 2,
    //requireDecimal: true
  });

  separatorKeysCodes: number[] = [ENTER, COMMA];
  usrCtrl = new UntypedFormControl();
  _usrCtrl = new UntypedFormControl();
  filteredOptions: Observable<User[]>;
  _ab_filteredOptions: Observable<User[]>;
  fUsers: User[] = [];
  fabUsers: User[] = [];
  fStatus: Array<string> = [
    'PENDING',
    'PROCESSED',
    'PAID',
    'PAID_APPROVED',
    'INVOICED',
    'RETURNED',
    'CANCELLATION_PENDING',
    'CANCELLED',
    'CLARIFICATION',
  ];

  fvg_codes: Array<string> = [];

  dpFC: any;
  dpTC: any;
  dpFA: any;
  dpTA: any;

  dpFCV: any;

  ready = true;
  oready = false;
  delivered = true;
  odelivered = false;
  returned = true;
  oreturned = false;
  odsr = false;
  arsr = false;
  ocod = false;

  courier: string;

  user$: Observable<User>;

  @ViewChild('usrInput') usrInput: ElementRef<HTMLInputElement>;
  @ViewChild('_usrInput') _usrInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    private activatedRoute: ActivatedRoute,
    private store: Store<AppState>,
    private router: Router,
    private layoutUtilsService: LayoutUtilsService,
    private subheaderService: SubheaderService,
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private actions$: Actions,
    private auth: AuthService,
    private dp: DatePipe,
  ) {}

  ngOnInit() {
    this.user$ = this.store.pipe(select(currentUser));

    if (this.router.url.startsWith('/shipment-management/admin')) {
      this.adminView = true;
      this.store.dispatch(new AllUsersRequested());
      this.displayedColumns = [
        'select',
        'user',
        'recipient_name',
        'date_approved',
        'cod_ammount',
        '_status',
        'vg_code',
        'is_ready',
        'is_delivered',
        'actions',
      ];
    } else {
      this.adminView = false;
    }

    this.serverError = new Subject();
    this.serverError.next(false);

    this.isPrinting = new BehaviorSubject<boolean>(false);
    this.isPrinting.next(false);

    const ss = this.actions$
      .pipe(
        ofType<ActionSuccess>(ShipmentActionTypes.ActionSuccess),
        tap(({ payload }) => {
          this.layoutUtilsService.showActionNotification(
            payload.result,
            MessageType.Delete,
            5000,
            true,
            true,
          );
        }),
      )
      .subscribe();
    this.subscriptions.push(ss);

    const sf = this.actions$
      .pipe(
        ofType<ActionFail>(ShipmentActionTypes.ActionFail),
        tap(({ payload }) => {
          if (
            // eslint-disable-next-line no-prototype-builtins
            payload.result.hasOwnProperty('error') &&
            // eslint-disable-next-line no-prototype-builtins
            payload.result.error.hasOwnProperty('detail')
          ) {
            this.serverError.next(payload.result.error.detail);
          } else if (
            payload.result ===
            'Συνέβη ένα σφάλμα κατα την έγκριση των αποστολών. Ενδέχεται ορισμένες να μην έχουν εγκριθεί.'
          ) {
            this.serverError.next(payload.result);
          } else {
            this.serverError.next(
              'Συνέβη ένα σφάλμα με την μεταβολή των δεδομένων. Παρακαλώ ξαναπροσπαθήστε.',
            );
          }

          // eslint-disable-next-line no-prototype-builtins
          if (payload.result.hasOwnProperty('ref_s') && payload.result.ref_s) {
            this.loadShipmentsList();
          }
        }),
      )
      .subscribe();
    this.subscriptions.push(sf);

    const arf = this.actions$
      .pipe(
        ofType<ActionRefresh>(ShipmentActionTypes.ActionRefresh),
        tap(({ payload }) => {
          this.loadShipmentsList();
        }),
      )
      .subscribe();
    this.subscriptions.push(arf);

    const maerr = this.actions$
      .pipe(
        ofType<MAError>(ShipmentActionTypes.MAError),
        tap((payload) => {
          let err =
            'Οι αποστολές ανανεώθηκαν ωστόσο προέκυψε σφάλμα με τις αποστολές στις γραμμές ';
          payload.payload.result.forEach((k) => (err += k.line + ' , '));
          err += ' οι οποίες και αγνοήθηκαν.';
          // let err_s = 'Οι αποστολές εισήχθησαν επιτυχώς. Ωστόσο οι απο
          this.serverError.next(err);
        }),
      )
      .subscribe();
    this.subscriptions.push(maerr);

    // If the user changes the sort order, reset back to the first page.
    const sortSubscription = this.sort.sortChange.subscribe(
      () => (this.paginator.pageIndex = 0),
    );
    this.subscriptions.push(sortSubscription);

    /* Data load will be triggered in two cases:
        - when a pagination event occurs => this.paginator.page
        - when a sort event occurs => this.sort.sortChange
         **/
    const paginatorSubscriptions = merge(
      this.sort.sortChange,
      this.paginator.page,
    )
      .pipe(
        tap(() => {
          this.loadShipmentsList();
        }),
      )
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);

    // Filtration, bind to searchInput
    const searchSubscription = fromEvent(
      this.searchInput.nativeElement,
      'keyup',
    )
      .pipe(
        debounceTime(250),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadShipmentsList();
        }),
      )
      .subscribe();
    this.subscriptions.push(searchSubscription);

    const searchSubscriptionTel = fromEvent(
      this.searchInputTel.nativeElement,
      'keyup',
    )
      .pipe(
        debounceTime(350),
        distinctUntilChanged(),
        tap(() => {
          this.paginator.pageIndex = 0;
          this.loadShipmentsList();
        }),
      )
      .subscribe();
    this.subscriptions.push(searchSubscriptionTel);

    // Set title to page breadCrumbs
    this.subheaderService.setTitle('Διαχείριση Αποστολών');

    // Init DataSource
    this.dataSource = new ShipmentsDataSource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject
      .pipe(skip(1), distinctUntilChanged())
      .subscribe((res) => {
        this.shipmentsResult = res;
      });
    this.subscriptions.push(entitiesSubscription);
    //Restore filters

    let fres: Observable<any>;
    if (this.adminView) {
      fres = this.store.pipe(select(selectListFilters));
    } else {
      fres = this.store.pipe(select(selectUserFilters));
    }

    if (this.adminView) {
      this.actions$
        .pipe(ofType<AllUsersLoaded>(UserActionTypes.AllUsersLoaded), take(1))
        .subscribe(() => {
          fres
            .pipe(
              take(1),
              tap((res) => this.restore_filters(res)),
              tap(() => this.loadShipmentsList()),
            )
            .subscribe();
        });
    } else {
      fres
        .pipe(
          take(1),
          tap((res) => this.restore_filters(res)),
          tap(() => this.loadShipmentsList()),
        )
        .subscribe();
    }

    //this.loadShipmentsList();
  }

  ngAfterViewInit() {}

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());
  }

  restore_filters(res: any) {
    if (!isEmpty(res)) {
      this.sort.direction = res.sortOrder;
      this.sort.active = res.sortField;
      this.paginator.pageIndex = res.pageNumber;
      this.paginator.pageSize = res.pageSize;

      if ('_status' in res.filter) {
        this.fStatus = [...res.filter._status];
      }

      if ('is_ready' in res.filter) {
        this.ready = res.filter.is_ready;
      }

      if ('is_returned' in res.filter) {
        this.returned = res.filter.is_returned;
      }

      if ('is_delivered' in res.filter) {
        this.delivered = res.filter.is_delivered;
      }

      if ('only_ready' in res.filter) {
        this.oready = res.filter.only_ready;
      }

      if ('only_returned' in res.filter) {
        this.oreturned = res.filter.only_returned;
      }

      if ('odsr' in res.filter) {
        this.odsr = res.filter.odsr;
      }

      if ('arsr' in res.filter) {
        this.arsr = res.filter.arsr;
      }

      if ('only_delivered' in res.filter) {
        this.odelivered = res.filter.only_delivered;
      }

      if ('recipient_name' in res.filter) {
        this.searchInput.nativeElement.value = res.filter.recipient_name;
      }

      if ('recipient_tel' in res.filter) {
        this.searchInputTel.nativeElement.value = res.filter.recipient_tel;
      }

      if ('cod' in res.filter) {
        this.ocod = res.filter.cod;
        if (res.filter.cod) {
          if ('ffap' in res.filter) {
            this.fFAp = res.filter.ffap;
            this.searchInputFA.nativeElement.value = res.filter.ffap;
          }
          if ('ftap' in res.filter) {
            this.fTAp = res.filter.ftap;
            this.searchInputTA.nativeElement.value = res.filter.ftap;
          }
        }
      }

      if ('courier' in res.filter) {
        this.courier = res.filter.courier;
      }

      if ('dpfc' in res.filter) {
        this.dpFC = moment(res.filter.dpfc);
      }

      if ('dptc' in res.filter) {
        this.dpTC = moment(res.filter.dptc);
      }

      if ('dpfa' in res.filter) {
        this.dpFA = moment(res.filter.dpfa);
      }

      if ('dpta' in res.filter) {
        this.dpTA = moment(res.filter.dpta);
      }

      if ('uids' in res.filter) {
        res.filter.uids.forEach((uid) => {
          this.store
            .pipe(
              select(selectUserById(uid)),
              take(1),
              tap((res) => this.fUsers.push(res)),
            )
            .subscribe();
        });
      }

      if ('_ab_uids' in res.filter) {
        res.filter._ab_uids.forEach((uid) => {
          this.store
            .pipe(
              select(selectUserById(uid)),
              take(1),
              tap((res) => this.fabUsers.push(res)),
            )
            .subscribe();
        });
      }

      if ('vg_codes' in res.filter) {
        this.fvg_codes = [...res.filter.vg_codes];
      }

      this.cdr.detectChanges();
    } else {
      this.paginator.pageIndex = 0;
      this.paginator.pageSize = 50;
      this.sort.active = 'date_created';
      this.sort.direction = 'desc';
    }
  }

  clearFilters() {
    if (this.adminView) {
      this.store.dispatch(new SetListFilters({ filters: {} }));
    } else {
      this.store.dispatch(new SetUserFilters({ filters: {} }));
    }

    const url = this.router.url;
    this.router
      .navigateByUrl('/', { skipLocationChange: true })
      .then(() => this.router.navigateByUrl(url));
  }

  vg_add(event: MatChipInputEvent) {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      this.fvg_codes.push(value.trim());
    }
    if (input) {
      input.value = '';
    }
    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  vg_remove(code: string) {
    const index = this.fvg_codes.indexOf(code);
    if (index >= 0) {
      this.fvg_codes.splice(index, 1);
    }
    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  //Filter shit here

  filterUsers(event) {
    const val = event.target.value;
    this.filteredOptions = this._filter(val);
  }

  _ab_filterUsers(event) {
    const val = event.target.value;
    this._ab_filteredOptions = this._ab_filter(val);
  }

  _filter(val: any): Observable<User[]> {
    if (typeof val === 'object') {
      return of(null);
    } else {
      return this.store.pipe(
        select(selectUsersInStore),
        map((res) => res.items),
        map((res: Array<User>) => res.filter((usr: User) => usr.id != 1)),
        map((res: Array<User>) =>
          res.filter(
            (usr: User) => !this.fUsers.some((el) => el.id === usr.id),
          ),
        ),
        map((res: Array<User>) =>
          res.filter(
            (usr: User) =>
              usr.id == val ||
              usr.kap == val ||
              usr.firstname.match(new RegExp(val, 'i')) ||
              usr.lastname.match(new RegExp(val, 'i')) ||
              usr.email.match(new RegExp(val, 'i')),
          ),
        ),
        take(1),
        map((res) => orderBy(res, ['id'], ['asc'])),
      );
    }
  }

  _ab_filter(val: any): Observable<User[]> {
    if (typeof val === 'object') {
      return of(null);
    } else {
      return this.store.pipe(
        select(selectUsersInStore),
        map((res) => res.items),
        map((res: Array<User>) => res.filter((usr: User) => usr.id != 1)),
        map((res: Array<User>) =>
          res.filter(
            (usr: User) => !this.fabUsers.some((el) => el.id === usr.id),
          ),
        ),
        map((res: Array<User>) =>
          res.filter(
            (usr: User) =>
              usr.id == val ||
              usr.kap == val ||
              usr.firstname.match(new RegExp(val, 'i')) ||
              usr.lastname.match(new RegExp(val, 'i')) ||
              usr.email.match(new RegExp(val, 'i')),
          ),
        ),
        take(1),
        map((res) => orderBy(res, ['id'], ['asc'])),
      );
    }
  }

  refs(): void {
    //this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  remove(usr: User): void {
    const index = this.fUsers.indexOf(usr);
    if (index >= 0) {
      this.fUsers.splice(index, 1);
      this.filteredOptions = this._filter('');
      this.paginator.pageIndex = 0;
      this.loadShipmentsList();
    }
  }

  _ab_remove(usr: User): void {
    const index = this.fabUsers.indexOf(usr);
    if (index >= 0) {
      this.fabUsers.splice(index, 1);
      this._ab_filteredOptions = this._ab_filter('');
      this.paginator.pageIndex = 0;
      this.loadShipmentsList();
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    this.fUsers.push(event.option.value);
    this.usrInput.nativeElement.value = '';
    this.usrCtrl.setValue(null);
    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  _ab_selected(event: MatAutocompleteSelectedEvent): void {
    this.fabUsers.push(event.option.value);
    this._usrInput.nativeElement.value = '';
    this._usrCtrl.setValue(null);
    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  courierChange($event) {
    this.loadShipmentsList();
  }

  readyStatusChanged(event) {
    if (event.source._checked) {
      this.ready = true;
    } else {
      this.ready = false;
    }

    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  oreadyStatusChanged(event) {
    if (event.source._checked) {
      this.oready = true;
    } else {
      this.oready = false;
    }

    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  deliveredStatusChanged(event) {
    if (event.source._checked) {
      this.delivered = true;
    } else {
      this.delivered = false;
    }

    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  odeliveredStatusChanged(event) {
    if (event.source._checked) {
      this.odelivered = true;
    } else {
      this.odelivered = false;
    }

    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  returnedStatusChanged(event) {
    if (event.source._checked) {
      this.returned = true;
    } else {
      this.returned = false;
    }

    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  oreturnedStatusChanged(event) {
    if (event.source._checked) {
      this.oreturned = true;
    } else {
      this.oreturned = false;
    }

    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  odsrStatusChanged(event) {
    if (event.source._checked) {
      this.odsr = true;
    } else {
      this.odsr = false;
    }

    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  arsrStatusChanged(event) {
    if (event.source._checked) {
      this.arsr = true;
    } else {
      this.arsr = false;
    }

    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  fStatusChanged(event) {
    if (event.source._checked) {
      this.fStatus = [...this.fStatus, event.value];
    } else {
      this.fStatus = this.fStatus.filter((e) => e !== event.value);
    }

    if (!this.adminView && event.value === 'PROCESSED') {
      if (event.source._checked) {
        this.fStatus = [...this.fStatus, 'PAID'];
      } else {
        this.fStatus = this.fStatus.filter((e) => e !== 'PAID');
      }
    }

    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  codChanged(event) {
    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  fTA(event) {
    this.fTAp = event.target.value.replace('€ ', '').replace(',', '');
    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  fFA(event) {
    this.fFAp = event.target.value.replace('€ ', '').replace(',', '');
    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  dpFCe(type: string, event: MatDatepickerInputEvent<any>) {
    //this.dpFC = event.value || undefined;
    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  dpTCe(type: string, event: MatDatepickerInputEvent<any>) {
    //this.dpTC = event.value || undefined;
    if (this.dpTC) {
      this.dpTC = this.dpTC
        .add(23, 'hours')
        .add(59, 'minutes')
        .add(59, 'seconds');
    }
    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  dpFAe(type: string, event: MatDatepickerInputEvent<any>) {
    //this.dpFA = event.value || undefined;
    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  dpTAe(type: string, event: MatDatepickerInputEvent<any>) {
    //this.dpTA = event.value || undefined;
    if (this.dpTA) {
      this.dpTA = this.dpTA
        .add(23, 'hours')
        .add(59, 'minutes')
        .add(59, 'seconds');
    }
    this.paginator.pageIndex = 0;
    this.loadShipmentsList();
  }

  //End filter shit
  loadShipmentsList() {
    this.selection.clear();
    const queryParams = new QueryParamsModel(
      this.filterConfiguration(),
      this.sort.direction,
      this.sort.active,
      this.paginator.pageIndex,
      this.paginator.pageSize,
    );

    if (this.adminView) {
      queryParams.for_staff = true;
    }

    this.store.dispatch(new ShipmentsPageRequested({ page: queryParams }));
    if (this.adminView) {
      this.store.dispatch(new SetListFilters({ filters: queryParams }));
    } else {
      this.store.dispatch(new SetUserFilters({ filters: queryParams }));
    }
    this.selection.clear();
  }

  /** FILTRATION */
  filterConfiguration(): any {
    const filter: any = {};

    filter.uids = this.fUsers.map((usr) => usr.id);
    filter._ab_uids = this.fabUsers.map((usr) => usr.id);
    filter.recipient_name = this.searchInput.nativeElement.value;
    filter.recipient_tel = this.searchInputTel.nativeElement.value;
    filter.vg_codes = [...this.fvg_codes];
    filter._status = this.fStatus;
    filter.cod = this.codToggle.checked;
    filter.ffap = this.fFAp;
    filter.ftap = this.fTAp;
    if (this.dpFC) {
      filter.dpfc = this.dpFC.format();
    }
    if (this.dpTC) {
      filter.dptc = this.dpTC.format();
    }
    if (this.dpFA) {
      filter.dpfa = this.dpFA.format();
    }
    if (this.dpTA) {
      filter.dpta = this.dpTA.format();
    }

    if (!this.ready) {
      filter.is_ready = false;
    }

    if (this.oready) {
      filter.only_ready = true;
    }
    if (!this.delivered) {
      filter.is_delivered = false;
    }

    if (this.odelivered) {
      filter.only_delivered = true;
    }
    if (!this.returned) {
      filter.is_returned = false;
    }

    if (this.oreturned) {
      filter.only_returned = true;
    }

    if (this.odsr) {
      filter.odsr = true;
    }

    if (this.arsr) {
      filter.arsr = true;
    }

    if (this.courier) {
      filter.courier = this.courier;
    }

    return filter;
  }

  /** ACTIONS */
  /**
   * Delete user
   *
   * @param _item: User
   */
  deleteShipment(_item: Shipment) {
    const _title = 'Διαγραφή Αποστολής';
    const _description = 'Είστε σίγουρος πως θέλετε να διαγράψετε την αποστολή';
    const _waitDesciption = 'Η αποστολή διαγράφεται ...';
    const _deleteMessage = `Η αποστολή έχει διαγραφεί`;

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption,
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.store.dispatch(new ShipmentDelete({ shipment: _item }));
    });
  }

  printVoucher(_item: Shipment) {
    this.store.dispatch(new FetchVoucher({ shipment: _item }));
    this.store
      .pipe(
        select(selectShipmentById(_item.id)),
        takeUntil(
          this.actions$.pipe(
            ofType<GotVoucher>(ShipmentActionTypes.GotVoucher),
          ),
        ),
        takeUntil(
          this.actions$.pipe(
            ofType<ActionFail>(ShipmentActionTypes.ActionFail),
          ),
        ),
        last(),
        tap((res: Shipment) => this.actualPrint(res)),
      )
      .subscribe();
  }

  printVouchers() {
    const _ids: Array<number> = [];
    this.isPrinting.next(true);

    this.selection.selected.forEach((elem) => {
      _ids.push(elem.id);
    });
    const httpHeaders = new HttpHeaders();
    httpHeaders.set('Content-Type', 'application/json');
    const res$: Observable<any> = this.http.post(
      'api/shipments/mass_print/',
      { ids: _ids },
      {
        headers: httpHeaders,
        observe: 'response',
        responseType: 'blob' as 'json',
      },
    );
    res$
      .pipe(
        /*
            mergeMap( res => {
                var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
                if( isFirefox ) {
                    this.pdfViewerOnDemand.pdfSrc = res.body;
                    this.pdfViewerOnDemand.refresh();
                    return of(res);
                } else {
                    var blob = res.body
                    const blobUrl = URL.createObjectURL(blob);
                    const iframe = document.createElement('iframe');
                    document.body.appendChild(iframe);
                    iframe.src = blobUrl;
                    iframe.style.display = 'none';
                    return of(iframe);
                }
            }),

            delay(4000),
            withLatestFrom(res$),
            map( ([ifr,res]) => {
                if( ifr) { ifr.contentWindow.print(); }
                return of(res);
            }),*/
        map((res) => {
          const isFirefox =
            navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
          if (isFirefox) {
            this.pdfViewerOnDemand.pdfSrc = res.body;
            this.pdfViewerOnDemand.refresh();
            return of(res);
          } else {
            const blob = res.body;
            const blobUrl = URL.createObjectURL(blob);
            const iframe = document.createElement('iframe');

            iframe.src = blobUrl;
            iframe.style.display = 'none';
            document.body.appendChild(iframe);
            iframe.contentWindow.print();
            return of(iframe);
          }
        }),
        delay(4000),
        catchError((err) => {
          if (err && err.error) {
            err.error.text().then((msg) => {
              if (msg) {
                this.store.dispatch(
                  new ActionFail({ result: { error: JSON.parse(msg) } }),
                );
              } else {
                this.store.dispatch(
                  new ActionFail({
                    result: {
                      error: { detail: 'Συνέβη ένα σφάλμα κατά την εκτύπωση.' },
                    },
                  }),
                );
              }
            });
          }
          return of(null);
        }),
        finalize(() => this.isPrinting.next(false)),
      )
      .subscribe();
  }

  actualPrint(item: Shipment) {
    if (!this.isPrinting.value) {
      this.isPrinting.next(true);
      const url = `/api/shipments/${item.id}/pdf_raw/`;
      this.http
        .get(url, {
          headers: new HttpHeaders({
            'Content-Type': 'application/octet-stream',
          }),
          responseType: 'blob',
        })
        .pipe(
          map((res) => {
            const isFirefox =
              navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
            if (isFirefox) {
              this.pdfViewerOnDemand.pdfSrc = res;
              this.pdfViewerOnDemand.refresh();
            } else {
              const blob = new Blob([res], { type: 'application/pdf' });
              const blobUrl = URL.createObjectURL(blob);
              const iframe = document.createElement('iframe');
              document.body.appendChild(iframe);
              iframe.src = blobUrl;
              iframe.style.display = 'none';
              return iframe;
            }
          }),
          delay(4000),
          tap((ifr) => {
            if (ifr) {
              ifr.contentWindow.print();
            }
          }),
          finalize(() => this.isPrinting.next(false)),
        )
        .subscribe();
    }
  }

  viewShipment(item: Shipment) {
    this.layoutUtilsService.viewShipment({ item: item, adm: this.adminView });
  }

  viewPickupOrder(item: Shipment) {
    //this.serverError.next('Η λειτουργία αυτή δεν έχει υλοποιηθεί ακόμα.');
    this.layoutUtilsService.viewPickupOrder({
      item: item,
      adm: this.adminView,
    });
  }

  viewVoucher(_item: Shipment) {
    this.store.dispatch(new FetchVoucher({ shipment: _item }));
    this.store
      .pipe(
        select(selectShipmentById(_item.id)),
        takeUntil(
          this.actions$.pipe(
            ofType<GotVoucher>(ShipmentActionTypes.GotVoucher),
          ),
        ),
        takeUntil(
          this.actions$.pipe(
            ofType<ActionFail>(ShipmentActionTypes.ActionFail),
          ),
        ),
        last(),
        tap((res: Shipment) =>
          this.layoutUtilsService.viewVoucher({ item: res }),
        ),
      )
      .subscribe();
  }

  createReturn(_item: Shipment) {
    const _title = 'Δημιουργία Επιστροφής';
    const _description =
      'Είστε σίγουρος πως θέλετε να δημιουργήσετε Επιστροφή για την Αποστολή;';
    const _waitDesciption = 'Η αποστολή δημιουργείται ...';

    const dialogRef = this.layoutUtilsService.approveElement(
      _title,
      _description,
      _waitDesciption,
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.store.dispatch(new CreateAutoReturn({ shipment: _item }));
    });
  }

  markReady(_item: Shipment) {
    const _title = 'Μαρκάρισμα Αποστολής ως Έτοιμη';
    const _description =
      'Είστε σίγουρος πως θέλετε να μαρκάρετε την αποστολή ως έτοιμη προς Αποστολή';
    const _waitDesciption = 'Η αποστολή εγκρίνεται ...';

    const dialogRef = this.layoutUtilsService.approveElement(
      _title,
      _description,
      _waitDesciption,
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.store.dispatch(new MarkReady({ shipment: _item }));
    });
  }

  unmarkReady(_item: Shipment) {
    const _title = 'Μαρκάρισμα Αποστολής ως μη Έτοιμη';
    const _description =
      'Είστε σίγουρος πως θέλετε να μαρκάρετε την αποστολή ως μη έτοιμη προς Αποστολή';
    const _waitDesciption = 'Η αποστολή εγκρίνεται ...';

    const dialogRef = this.layoutUtilsService.approveElement(
      _title,
      _description,
      _waitDesciption,
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.store.dispatch(new UnMarkReady({ shipment: _item }));
    });
  }

  approveShipment(_item: Shipment) {
    const _title = 'Έγκριση Αποστολής';
    const _description = 'Είστε σίγουρος πως θέλετε να εγκρίνετε την αποστολή';
    const _waitDesciption = 'Η αποστολή εγκρίνεται ...';

    const dialogRef = this.layoutUtilsService.approveElement(
      _title,
      _description,
      _waitDesciption,
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.store.dispatch(new ShipmentApprove({ shipment: _item }));
    });
  }

  massMarkReady() {
    // Mass shipment delete here
    const _title = 'Μαρκάρισμα ως Έτοιμες';
    const _description =
      'Είστε σίγουρος πως θέλετε να μαρκάρετε τις αποστολές ως έτοιμες προς αποστολή;';
    const _waitDesciption = 'Οι αποστολές μαρκάρονται';

    const dialogRef = this.layoutUtilsService.approveElement(
      _title,
      _description,
      _waitDesciption,
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      const _ids: Array<number> = [];

      this.selection.selected.forEach((elem) => {
        _ids.push(elem.id);
      });
      this.store.dispatch(new MassMarkReady({ ids: _ids }));
    });
  }

  massUnMarkReady() {
    // Mass shipment delete here
    const _title = 'Μαρκάρισμα ως μη Έτοιμες';
    const _description =
      'Είστε σίγουρος πως θέλετε να μαρκάρετε τις αποστολές ως μη έτοιμες προς αποστολή;';
    const _waitDesciption = 'Οι αποστολές μαρκάρονται';

    const dialogRef = this.layoutUtilsService.approveElement(
      _title,
      _description,
      _waitDesciption,
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      const _ids: Array<number> = [];

      this.selection.selected.forEach((elem) => {
        _ids.push(elem.id);
      });
      this.store.dispatch(new MassUnMarkReady({ ids: _ids }));
    });
  }

  markPending(_item: Shipment) {
    const _title = 'Μαρκάρισμα Αποστολής ως Εκκρεμεί';
    const _description =
      'Είστε σίγουρος πως θέλετε να μαρκάρετε την αποστολή ως Εκκρεμεί';
    const _waitDesciption = 'Η αλλαγή καταχωρείται';

    const dialogRef = this.layoutUtilsService.approveElement(
      _title,
      _description,
      _waitDesciption,
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.store.dispatch(new MarkPending({ shipment: _item }));
    });
  }

  deleteShipments() {
    // Mass shipment delete here
    const _title = 'Διαγραφή Αποστολών';
    const _description =
      'Είστε σίγουρος πως θέλετε να διαγράψετε τις αποστολές;';
    const _waitDesciption = 'Οι αποστολές διαγράφονται';

    const dialogRef = this.layoutUtilsService.deleteElement(
      _title,
      _description,
      _waitDesciption,
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      const _ids: Array<number> = [];

      this.selection.selected.forEach((elem) => {
        _ids.push(elem.id);
      });
      this.store.dispatch(new ShipmentMassDelete({ ids: _ids }));
    });
  }

  onFileSelected(event) {
    const inputNode: any = document.querySelector('#file');
    const file: File = event.target.files[0];

    import('xlsx').then((XLSX) => {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const arrayBuffer: any = fileReader.result;
        const data = new Uint8Array(arrayBuffer);
        const workbook: import('xlsx').WorkBook = XLSX.read(data, {
          type: 'array',
        });
        const first_sheet_name = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[first_sheet_name];
        const res: Array<any> = XLSX.utils.sheet_to_json(worksheet, {
          raw: true,
          header: 'A',
        });
        let line = 0;
        let error = false;

        res.forEach((o) => {
          line++;
          delete Object.assign(o, { ['recipient_name']: o['A'] })['A'];
          delete Object.assign(o, { ['recipient_address']: o['B'] })['B'];
          delete Object.assign(o, { ['recipient_postcode']: o['C'] })['C'];
          delete Object.assign(o, { ['recipient_area']: o['D'] })['D'];
          delete Object.assign(o, { ['recipient_phone']: o['E'] })['E'];
          delete Object.assign(o, { ['recipient_phone2']: o['F'] })['F'];
          delete Object.assign(o, { ['user_comments']: o['G'] })['G'];
          delete Object.assign(o, { ['sh_comments']: o['H'] })['H'];
          delete Object.assign(o, { ['voucher_a6']: o['I'] })['I'];

          o.voucher_a6 = o.voucher_a6 === 'A6';

          delete Object.assign(o, { ['cod_ammount']: o['L'] })['L'];

          if (o.cod_ammount && o.cod_ammount !== 0) {
            o['is_cod'] = true;
          } else {
            o['is_cod'] = false;
          }

          o['line'] = line;

          delete Object.assign(o, { ['weight']: o['J'] })['J'];
          delete Object.assign(o, { ['items']: o['K'] })['K'];

          if (
            !o.recipient_name ||
            o.recipient_name === '' ||
            !o.recipient_address ||
            o.recipient_address === '' ||
            !o.recipient_postcode ||
            o.recipient_postcode === '' ||
            !o.recipient_area ||
            o.recipient_area === '' ||
            !o.recipient_phone ||
            o.recipient_phone === '' ||
            o.cod_ammount < 0 ||
            !o.weight ||
            o.weight <= 0 ||
            !o.items ||
            o.items <= 0
          ) {
            this.serverError.next(
              `Εντοπίστηκε σφάλμα στην γραμμή ${line}. Παρακαλώ σιγουρευτείτε πως όλα τα δεδομένα που συμπληρώσατε είναι σωστά!`,
            );
            error = true;
          }

          if (this.adminView) {
            delete Object.assign(o, { ['kap']: o['M'] })['M'];

            if (!o.kap) {
              this.serverError.next(
                `Εντοπίστηκε σφάλμα στην γραμμή ${line}. Παρακαλώ σιγουρευτείτε πως όλα τα δεδομένα που συμπληρώσατε είναι σωστά!`,
              );
              error = true;
            }
          }
        });

        if (!error) {
          this.store.dispatch(new AddByXLS({ items: res }));
        }
      };
      fileReader.readAsArrayBuffer(file);
    });
  }

  genXLS() {
    import('xlsx').then((XLSX) => {
      // eslint-disable-next-line @typescript-eslint/ban-types
      const data: Array<Object> = [];

      this.selection.selected.forEach((elem) => {
        const o: { [k: string]: any } = {};
        o['Όνομα Παραλήπτη'] = elem.recipient_name;
        o['Διεύθυνση Παραλήπτη'] = elem.recipient_address;
        o['Περιοχή Παραλήπτη'] = elem.recipient_selected_area
          ? elem.recipient_selected_area.name +
            ' ' +
            elem.recipient_selected_area.nomos
          : elem.recipient_area;
        o['Τηλέφωνο Παραλήπτη'] = elem.recipient_phone;
        o['TK Παραλήπτη'] = elem.recipient_postcode;
        o['Παρατηρήσεις'] = elem.user_comments
          ? `(${elem.id}) ${elem.user_comments}`
          : `(${elem.id})`;
        o['Ημερομηνία Έγκρισης'] = elem.date_approved
          ? this.dp.transform(elem.date_approved, 'dd/MM/yyyy')
          : '';
        this.store
          .pipe(
            select(selectUserById(elem.user)),
            take(1),
            tap((res) => {
              o['Αποστολέας'] =
                res.id + ' ' + res.lastname + ' ' + res.firstname;
              o['Όνομα Παραλήπτη'] = `${res.id} ${o['Όνομα Παραλήπτη']}`;
            }),
          )
          .subscribe();
        //o['Αποστολέας'] = elem.user;
        o['Ποσό Αντικαταβολής'] = elem.cod_ammount || 0;
        o['Κωδικός Αποστολής'] = elem.vg_code;
        o['Βάρος'] = elem.weight;
        o['Τεμάχια'] = elem.items;
        o['Σχόλια'] = elem.sh_comments;
        data.push(o);
      });
      const workSheet = XLSX.utils.json_to_sheet(data);
      const workBook: import('xlsx').WorkBook = XLSX.utils.book_new();
      const wscols = [
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
        { width: 20 },
      ];
      workSheet['!cols'] = wscols;
      XLSX.utils.book_append_sheet(workBook, workSheet, 'Αποστολές');
      XLSX.writeFile(
        workBook,
        this.makeid(10) + '-' + this.makeid(4) + '.xlsx',
      );
    });
  }

  makeid(length) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  approveShipments() {
    // Mass shipment delete here
    const _title = 'Έγκριση Αποστολών';
    const _description =
      'Είστε σίγουρος πως θέλετε να εγκρίνετε τις αποστολές;';
    const _waitDesciption = 'Οι αποστολές εγκρίνονται';

    const dialogRef = this.layoutUtilsService.approveElement(
      _title,
      _description,
      _waitDesciption,
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      const _ids: Array<number> = [];

      this.selection.selected.forEach((elem) => {
        _ids.push(elem.id);
      });
      this.store.dispatch(new ShipmentMassApprove({ ids: _ids }));
    });
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.shipmentsResult.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.selection.selected.length === this.shipmentsResult.length) {
      this.selection.clear();
    } else {
      this.shipmentsResult.forEach((row) => this.selection.select(row));
    }
  }

  /**
   * Redirect to edit page
   *
   * @param id
   */
  editShipment(id) {
    this.router.navigate(['../shipments/edit', id], {
      relativeTo: this.activatedRoute,
    });
  }

  onAlertClose($event) {
    this.serverError.next(false);
  }
}
