// Angular
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
// RxJS
import { Observable, Subject, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
// AppState
import { AppState } from '../../../../../../core/reducers';
import {
  LayoutUtilsService,
  MessageType,
} from '../../../../../../core/_base/crud';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import {
  GCActionFail,
  GCActionSuccess,
  GConfig,
  GConfigActionTypes,
  GConfigBulkUpdate,
  selectGConfigById,
} from '../../../../../../core/rex';

@Component({
  selector: 'kt-speedex',
  templateUrl: './speedex.component.html',
  styleUrls: ['./speedex.component.scss'],
})
export class SpeedexComponent implements OnInit, OnDestroy {
  @Input() saveEvent: Observable<void>;

  spdx_username: GConfig = new GConfig();
  spdx_password: GConfig = new GConfig();
  spdx_customer_id: GConfig = new GConfig();
  spdx_agreement_id: GConfig = new GConfig();
  spdx_agreement_desc: GConfig = new GConfig();
  spdx_api_endpoint: GConfig = new GConfig();

  hasFormErrors = false;
  serverError: Subject<any>;
  speedexForm: UntypedFormGroup;

  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<AppState>,
    private layoutUtilsService: LayoutUtilsService,
    private actions$: Actions,
    private speedexFB: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.serverError = new Subject();
    this.serverError.next(false);

    const ss = this.saveEvent.subscribe(() => this.save());
    this.subscriptions.push(ss);

    this.fetchDataFromStore();
    this.createForm();

    const sss = this.actions$
      .pipe(
        ofType<GCActionSuccess>(GConfigActionTypes.GCActionSuccess),
        tap(({ payload }) => {
          this.layoutUtilsService.showActionNotification(
            payload.result,
            MessageType.Update,
            5000,
            true,
            true,
          );
        }),
      )
      .subscribe();
    this.subscriptions.push(sss);

    const sf = this.actions$
      .pipe(
        ofType<GCActionFail>(GConfigActionTypes.GCActionFail),
        tap(({ payload }) => {
          if (
            // eslint-disable-next-line no-prototype-builtins
            payload.result.hasOwnProperty('error') &&
            // eslint-disable-next-line no-prototype-builtins
            payload.result.error.hasOwnProperty('detail')
          ) {
            this.serverError.next(payload.result.error.detail);
          } else {
            this.serverError.next(
              'Συνέβη ένα σφάλμα με την αποθήκευση των δεδομένων. Παρακαλώ ξαναπροσπαθήστε.',
            );
          }
        }),
      )
      .subscribe();
    this.subscriptions.push(sf);
  }

  fetchDataFromStore() {
    this.store
      .pipe(
        select(selectGConfigById('hidden__SPDX_USERNAME')),
        take(1),
        tap((res) => (this.spdx_username = { ...res })),
      )
      .subscribe();

    this.store
      .pipe(
        select(selectGConfigById('hidden__SPDX_PASSWORD')),
        take(1),
        tap((res) => (this.spdx_password = { ...res })),
      )
      .subscribe();

    this.store
      .pipe(
        select(selectGConfigById('hidden__SPDX_CUSTOMER_ID')),
        take(1),
        tap((res) => (this.spdx_customer_id = { ...res })),
      )
      .subscribe();

    this.store
      .pipe(
        select(selectGConfigById('hidden__SPDX_AGREEMENT_ID')),
        take(1),
        tap((res) => (this.spdx_agreement_id = { ...res })),
      )
      .subscribe();

    this.store
      .pipe(
        select(selectGConfigById('hidden__SPDX_AGREEMENT_DESC')),
        take(1),
        tap((res) => (this.spdx_agreement_desc = { ...res })),
      )
      .subscribe();

    this.store
      .pipe(
        select(selectGConfigById('hidden__SPDX_API_ENDPOINT')),
        take(1),
        tap((res) => (this.spdx_api_endpoint = { ...res })),
      )
      .subscribe();
  }

  createForm() {
    const fgd: { [k: string]: any } = {
      spdx_username: [this.spdx_username.value, Validators.required],
      spdx_password: [this.spdx_password.value, Validators.required],
      spdx_customer_id: [this.spdx_customer_id.value, Validators.required],
      spdx_agreement_id: [this.spdx_agreement_id.value, Validators.required],
      spdx_agreement_desc: [this.spdx_agreement_desc.value],
      spdx_api_endpoint: [this.spdx_api_endpoint.value, Validators.required],
    };

    this.speedexForm = this.speedexFB.group(fgd);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  save() {
    this.hasFormErrors = false;
    this.serverError.next(false);

    const controls = this.speedexForm.controls;
    if (this.speedexForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched(),
      );

      this.hasFormErrors = true;
      return;
    }

    this.spdx_username.value = controls.spdx_username.value;
    this.spdx_password.value = controls.spdx_password.value;
    this.spdx_customer_id.value = controls.spdx_customer_id.value;
    this.spdx_agreement_id.value = controls.spdx_agreement_id.value;
    this.spdx_agreement_desc.value = controls.spdx_agreement_desc.value;
    this.spdx_api_endpoint.value = controls.spdx_api_endpoint.value;

    const buo: { [key: string]: any } = {};
    buo[this.spdx_username.identifier] = this.spdx_username.value;
    buo[this.spdx_password.identifier] = this.spdx_password.value;
    buo[this.spdx_customer_id.identifier] = this.spdx_customer_id.value;
    buo[this.spdx_agreement_id.identifier] = this.spdx_agreement_id.value;
    buo[this.spdx_agreement_desc.identifier] = this.spdx_agreement_desc.value;
    buo[this.spdx_api_endpoint.identifier] = this.spdx_api_endpoint.value;

    this.store.dispatch(new GConfigBulkUpdate({ values: buo }));
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
    this.serverError.next(false);
  }
}
