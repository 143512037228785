// Angular
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
// RxJS
import { Observable, Subject, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
// AppState
import { AppState } from '../../../../../../core/reducers';
import {
  LayoutUtilsService,
  MessageType,
} from '../../../../../../core/_base/crud';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import {
  GCActionFail,
  GCActionSuccess,
  GConfig,
  GConfigActionTypes,
  GConfigBulkUpdate,
  selectGConfigById,
} from '../../../../../../core/rex';

@Component({
  selector: 'kt-courier-center',
  templateUrl: './courier-center.component.html',
  styleUrls: ['./courier-center.component.scss'],
})
export class CourierCenterComponent implements OnInit, OnDestroy {
  @Input() saveEvent: Observable<void>;

  cs_api_endpoint: GConfig = new GConfig();
  cs_account_code: GConfig = new GConfig();
  cs_user_alias: GConfig = new GConfig();
  cs_credential_value: GConfig = new GConfig();
  cs_api_key: GConfig = new GConfig();

  hasFormErrors = false;
  serverError: Subject<any>;
  csForm: UntypedFormGroup;
  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<AppState>,
    private layoutUtilsService: LayoutUtilsService,
    private actions$: Actions,
    private csFB: UntypedFormBuilder,
  ) {}

  ngOnInit() {
    this.serverError = new Subject();
    this.serverError.next(false);

    const ss = this.saveEvent.subscribe(() => this.save());
    this.subscriptions.push(ss);

    this.fetchDataFromStore();
    this.createForm();

    const sss = this.actions$
      .pipe(
        ofType<GCActionSuccess>(GConfigActionTypes.GCActionSuccess),
        tap(({ payload }) => {
          this.layoutUtilsService.showActionNotification(
            payload.result,
            MessageType.Update,
            5000,
            true,
            true,
          );
        }),
      )
      .subscribe();
    this.subscriptions.push(sss);

    const sf = this.actions$
      .pipe(
        ofType<GCActionFail>(GConfigActionTypes.GCActionFail),
        tap(({ payload }) => {
          if (
            // eslint-disable-next-line no-prototype-builtins
            payload.result.hasOwnProperty('error') &&
            // eslint-disable-next-line no-prototype-builtins
            payload.result.error.hasOwnProperty('detail')
          ) {
            this.serverError.next(payload.result.error.detail);
          } else {
            this.serverError.next(
              'Συνέβη ένα σφάλμα με την αποθήκευση των δεδομένων. Παρακαλώ ξαναπροσπαθήστε.',
            );
          }
        }),
      )
      .subscribe();
    this.subscriptions.push(sf);
  }

  fetchDataFromStore() {
    this.store
      .pipe(
        select(selectGConfigById('hidden__CS_API_ENDPOINT')),
        take(1),
        tap((res) => (this.cs_api_endpoint = { ...res })),
      )
      .subscribe();

    this.store
      .pipe(
        select(selectGConfigById('hidden__CS_ACCOUNT_CODE')),
        take(1),
        tap((res) => (this.cs_account_code = { ...res })),
      )
      .subscribe();

    this.store
      .pipe(
        select(selectGConfigById('hidden__CS_USER_ALIAS')),
        take(1),
        tap((res) => (this.cs_user_alias = { ...res })),
      )
      .subscribe();

    this.store
      .pipe(
        select(selectGConfigById('hidden__CS_CREDENTIAL_VALUE')),
        take(1),
        tap((res) => (this.cs_credential_value = { ...res })),
      )
      .subscribe();

    this.store
      .pipe(
        select(selectGConfigById('hidden__CS_API_KEY')),
        take(1),
        tap((res) => (this.cs_api_key = { ...res })),
      )
      .subscribe();
  }

  createForm() {
    const fgd: { [k: string]: any } = {
      cs_api_endpoint: [this.cs_api_endpoint.value, Validators.required],
      cs_account_code: [this.cs_account_code.value, Validators.required],
      cs_user_alias: [this.cs_user_alias.value, Validators.required],
      cs_credential_value: [
        this.cs_credential_value.value,
        Validators.required,
      ],
      cs_api_key: [this.cs_api_key.value, Validators.required],
    };

    this.csForm = this.csFB.group(fgd);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  save() {
    this.hasFormErrors = false;
    this.serverError.next(false);

    const controls = this.csForm.controls;
    if (this.csForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched(),
      );

      this.hasFormErrors = true;
      return;
    }

    this.cs_api_endpoint.value = controls.cs_api_endpoint.value;
    this.cs_account_code.value = controls.cs_account_code.value;
    this.cs_user_alias.value = controls.cs_user_alias.value;
    this.cs_credential_value.value = controls.cs_credential_value.value;
    this.cs_api_key.value = controls.cs_api_key.value;

    const buo: { [key: string]: any } = {};
    buo[this.cs_api_endpoint.identifier] = this.cs_api_endpoint.value;
    buo[this.cs_account_code.identifier] = this.cs_account_code.value;
    buo[this.cs_user_alias.identifier] = this.cs_user_alias.value;
    buo[this.cs_credential_value.identifier] = this.cs_credential_value.value;
    buo[this.cs_api_key.identifier] = this.cs_api_key.value;

    this.store.dispatch(new GConfigBulkUpdate({ values: buo }));
  }

  onAlertClose($event) {
    this.hasFormErrors = false;
    this.serverError.next(false);
  }
}
