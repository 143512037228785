// Angular
import { Injectable } from '@angular/core';
// RxJS
import {
  catchError,
  concatMap,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
// NGRX
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
// CRUD
import { QueryParamsModel, QueryResultsModel } from '../../_base/crud';
// Services
import { ShipmentService } from '../_services/shipment.service';
// State
import { AppState } from '../../reducers';
import {
  ActionFail,
  ActionRefresh,
  ActionSuccess,
  AddByXLS,
  AError,
  CheckKgByXLS,
  CheckKgByXLS_ACS,
  CheckReturned,
  CreateAutoReturn,
  CSError,
  EError,
  FetchedShipment,
  FetchShipment,
  FetchVoucher,
  GotVoucher,
  Liquidate,
  MAError,
  ManualReturnCreate,
  MarkAsApprovedByXLS_GENIKI,
  MarkAsPaidByXLS,
  MarkAsPaidByXLS_ACS,
  MarkAsPaidByXLS_CS,
  MarkAsPaidByXLS_GENIKI,
  MarkAsPaidByXLS_SPDX,
  MarkAsPaidByXLS_TAXY,
  MarkCancellationPending,
  MarkClarification,
  MarkInvoiced,
  MarkPaid,
  MarkPaidApproved,
  MarkPending,
  MarkPrepaid,
  MarkPrereturn,
  MarkProcessed,
  MarkReady,
  MarkReturned,
  MassMarkCancellationPending,
  MassMarkInvoiced,
  MassMarkPaid,
  MassMarkPaidApproved,
  MassMarkPrepaid,
  MassMarkProcessed,
  MassMarkReady,
  MassUnMarkReady,
  ReturnCreate,
  ReturnDeliveredXLS,
  SendDeliveredXLS,
  SError,
  ShipmentActionTypes,
  ShipmentApprove,
  ShipmentCancel,
  ShipmentCheckWeight,
  ShipmentCreate,
  ShipmentDelete,
  ShipmentMassApprove,
  ShipmentMassCheckWeight,
  ShipmentMassDelete,
  ShipmentMassRemove,
  ShipmentMassUpsert,
  ShipmentRemove,
  ShipmentsActionToggleLoading,
  ShipmentsPageLoaded,
  ShipmentsPageRequested,
  ShipmentsPageToggleLoading,
  ShipmentUpdate,
  ShipmentUpsert,
  ToggleReturnDelivered,
  ToggleSendDelivered,
  UNFError,
  UnMarkPrepaid,
  UnMarkPrereturn,
  UnMarkReady,
  ValidateSPDX,
} from '../_actions/shipment.actions';

@Injectable()
export class ShipmentEffects {
  showPageLoadingDistpatcher = new ShipmentsPageToggleLoading({
    isLoading: true,
  });
  hidePageLoadingDistpatcher = new ShipmentsPageToggleLoading({
    isLoading: false,
  });

  showActionLoadingDistpatcher = new ShipmentsActionToggleLoading({
    isLoading: true,
  });
  hideActionLoadingDistpatcher = new ShipmentsActionToggleLoading({
    isLoading: false,
  });

  deleteShipments$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ShipmentMassDelete>(ShipmentActionTypes.ShipmentMassDelete),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.deleteShipments(payload.ids).pipe(
          switchMap((res) => [
            new ShipmentMassRemove({ ids: res }),
            new ActionSuccess({ result: `Οι αποστολές διαγράφηκαν επιτυχώς` }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  approveShipments$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ShipmentMassApprove>(ShipmentActionTypes.ShipmentMassApprove),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      concatMap((payload) =>
        this.ss.approveShipments(payload.ids).pipe(
          switchMap((res) => {
            if (res.lerror) {
              return [
                new ShipmentMassUpsert({ shipments: res.items }),
                new ActionFail({
                  result: `Συνέβη ένα σφάλμα κατα την έγκριση των αποστολών. Ενδέχεται ορισμένες να μην έχουν εγκριθεί.`,
                }),
              ];
            } else {
              return [
                new ShipmentMassUpsert({ shipments: res.items }),
                new ActionSuccess({
                  result: `Οι αποστολές εγκρίθηκαν επιτυχώς`,
                }),
              ];
            }
          }),
          catchError((err) => {
            err.ref_s = true;
            return [new ActionFail({ result: err })];
          }),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  liquidate$ = createEffect(() =>
    this.actions$.pipe(
      ofType<Liquidate>(ShipmentActionTypes.Liquidate),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.liquidate(payload.fdata).pipe(
          switchMap((res) => [
            new ShipmentMassUpsert({ shipments: res.items }),
            new ActionSuccess({ result: `Η εκκαθάριση ολοκληρώθηκε επιτυχώς` }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  deleteShipment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ShipmentDelete>(ShipmentActionTypes.ShipmentDelete),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.deleteShipment(payload.shipment).pipe(
          switchMap(() => [
            new ShipmentRemove({ shipment: payload.shipment }),
            new ActionSuccess({
              result: `Η αποστολή προς ${payload.shipment.recipient_name} διαγράφηκε επιτυχώς`,
            }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  markPaid$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkPaid>(ShipmentActionTypes.MarkPaid),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.markPaid(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new ActionSuccess({
              result: `Η αποστολή καταχωρήθηκε ως Εισπραγμένη`,
            }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  markPending$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkPending>(ShipmentActionTypes.MarkPending),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.markPending(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new ActionSuccess({
              result: `Η αποστολή καταχωρήθηκε ως Εκκρεμής`,
            }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  checkReturned$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CheckReturned>(ShipmentActionTypes.CheckReturned),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.checkReturned(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new ActionSuccess({ result: `Η αποστολή ελέγχθηκε επιτυχώς` }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  shipmentCancel$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ShipmentCancel>(ShipmentActionTypes.ShipmentCancel),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.shipmentCancel(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new ActionSuccess({ result: `Η αποστολή ακυρώθηκε επιτυχώς` }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  createAutoReturn$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CreateAutoReturn>(ShipmentActionTypes.CreateAutoReturn),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      concatMap((payload) =>
        this.ss.createAutoReturn(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new ActionSuccess({ result: `Η αποστολή δημιουργήθηκε επιτυχώς` }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  toggleReturnDelivered$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ToggleReturnDelivered>(ShipmentActionTypes.ToggleReturnDelivered),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.toggleReturnDelivered(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new ActionSuccess({ result: `Η αποστολή ανανεώθηκε επιτυχώς` }),
            //new ActionRefresh({ result : true})
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  toggleSendDelivered$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ToggleSendDelivered>(ShipmentActionTypes.ToggleSendDelivered),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.toggleSendDelivered(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new ActionSuccess({ result: `Η αποστολή ανανεώθηκε επιτυχώς` }),
            //new ActionRefresh({ result : true})
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  markClarification$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkClarification>(ShipmentActionTypes.MarkClarification),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.markClarification(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new ActionSuccess({
              result: `Η αποστολή καταχωρήθηκε ως Προς Διασάφηση`,
            }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  markPaidApproved$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkPaidApproved>(ShipmentActionTypes.MarkPaidApproved),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.markPaidApproved(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new ActionSuccess({
              result: `Η αποστολή καταχωρήθηκε ως Προς Απόδοση`,
            }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  markInvoiced$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkInvoiced>(ShipmentActionTypes.MarkInvoiced),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.markInvoiced(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new ActionSuccess({
              result: `Η αποστολή καταχωρήθηκε ως Εξοφλημένη`,
            }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  markCancellationPending$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkCancellationPending>(
        ShipmentActionTypes.MarkCancellationPending,
      ),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.markCancellationPending(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new ActionSuccess({
              result: `Η αποστολή καταχωρήθηκε προς Ακύρωση`,
            }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  markProcessed$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkProcessed>(ShipmentActionTypes.MarkProcessed),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.markProcessed(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new ActionSuccess({
              result: `Η αποστολή καταχωρήθηκε ως Εγκεκριμένη`,
            }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  markReturned$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkReturned>(ShipmentActionTypes.MarkReturned),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.markReturned(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new ActionSuccess({
              result: `Η αποστολή καταχωρήθηκε ως Επεστραμένη`,
            }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  markPrepaid$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkPrepaid>(ShipmentActionTypes.MarkPrepaid),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.markPrepaid(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new ActionSuccess({
              result: `Η αποστολή καταχωρήθηκε ως Προπληρωμένη `,
            }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  unmarkPrepaid$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UnMarkPrepaid>(ShipmentActionTypes.UnMarkPrepaid),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.unmarkPrepaid(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new ActionSuccess({
              result: `Η αποστολή καταχωρήθηκε ως μή Προπληρωμένη `,
            }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  markPrereturn$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkPrereturn>(ShipmentActionTypes.MarkPrereturn),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.markPrereturn(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new ActionSuccess({
              result: `Η αποστολή καταχωρήθηκε ως Προ-Αποδομένη `,
            }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  unmarkPrereturn$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UnMarkPrereturn>(ShipmentActionTypes.UnMarkPrereturn),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.unmarkPrereturn(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new ActionSuccess({
              result: `Η αποστολή καταχωρήθηκε ως μή Προ-Αποδομένη`,
            }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  markReady$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkReady>(ShipmentActionTypes.MarkReady),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.markReady(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new ActionSuccess({ result: `Η αποστολή καταχωρήθηκε ως Έτοιμη ` }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  unmarkReady$ = createEffect(() =>
    this.actions$.pipe(
      ofType<UnMarkReady>(ShipmentActionTypes.UnMarkReady),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.unmarkReady(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new ActionSuccess({
              result: `Η αποστολή καταχωρήθηκε ως μή Έτοιμη `,
            }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  massMarkPrepaid$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MassMarkPrepaid>(ShipmentActionTypes.MassMarkPrepaid),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.massMarkPrepaid(payload.ids).pipe(
          switchMap((res) => [
            new ShipmentMassUpsert({ shipments: res.items }),
            new ActionSuccess({ result: `Οι αποστολές ανανεώθηκαν επιτυχώς` }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => {
            err.ref_s = true;
            return [new ActionFail({ result: err })];
          }),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  massMarkReady$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MassMarkReady>(ShipmentActionTypes.MassMarkReady),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.massMarkReady(payload.ids).pipe(
          switchMap((res) => [
            new ShipmentMassUpsert({ shipments: res.items }),
            new ActionSuccess({ result: `Οι αποστολές ανανεώθηκαν επιτυχώς` }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => {
            err.ref_s = true;
            return [new ActionFail({ result: err })];
          }),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  massMarkCancellationPending$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MassMarkCancellationPending>(
        ShipmentActionTypes.MassMarkCancellationPending,
      ),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.massMarkCancellationPending(payload.ids).pipe(
          switchMap((res) => [
            new ShipmentMassUpsert({ shipments: res.items }),
            new ActionSuccess({ result: `Οι αποστολές ανανεώθηκαν επιτυχώς` }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => {
            err.ref_s = true;
            return [new ActionFail({ result: err })];
          }),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  massUnMarkReady$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MassUnMarkReady>(ShipmentActionTypes.MassUnMarkReady),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.massUnMarkReady(payload.ids).pipe(
          switchMap((res) => [
            new ShipmentMassUpsert({ shipments: res.items }),
            new ActionSuccess({ result: `Οι αποστολές ανανεώθηκαν επιτυχώς` }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => {
            err.ref_s = true;
            return [new ActionFail({ result: err })];
          }),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  markAsPaidByXLS$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkAsPaidByXLS>(ShipmentActionTypes.MarkAsPaidByXLS),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.markAsPaidByXLS(payload.items).pipe(
          switchMap((res) => {
            const ret: Array<any> = [
              new ShipmentMassUpsert({ shipments: res.items }),
              new ActionSuccess({
                result: `Οι αποστολές ανανεώθηκαν επιτυχώς`,
              }),
              new ActionRefresh({ result: true }),
            ];
            if (res.eerror) {
              ret.push(new EError({ result: res.eerror }));
            }
            if (res.serror) {
              ret.push(new SError({ result: res.serror }));
            }
            if (res.cs > 0) {
              ret.push(
                new CSError({
                  result: `Βρέθηκαν ${res.cs} αποστολές με λάθος ποσό αντικαταβολής και διορθώθηκαν. Οι παρακάτω: ${res.cs_codes}`,
                }),
              );
            }
            return ret;
          }),
          catchError((err) => {
            err.ref_s = true;
            return [new ActionFail({ result: err })];
          }),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  markAsPaidByXLS_SPDX$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkAsPaidByXLS_SPDX>(ShipmentActionTypes.MarkAsPaidByXLS_SPDX),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.markAsPaidByXLS_SPDX(payload.items).pipe(
          switchMap((res) => {
            const ret: Array<any> = [
              new ShipmentMassUpsert({ shipments: res.items }),
              new ActionSuccess({
                result: `Οι αποστολές ανανεώθηκαν επιτυχώς`,
              }),
              new ActionRefresh({ result: true }),
            ];
            if (res.eerror) {
              ret.push(new EError({ result: res.eerror }));
            }
            if (res.serror) {
              ret.push(new SError({ result: res.serror }));
            }
            if (res.cs > 0) {
              ret.push(
                new CSError({
                  result: `Βρέθηκαν ${res.cs} αποστολές με λάθος ποσό αντικαταβολής και διορθώθηκαν. Οι παρακάτω: ${res.cs_codes}`,
                }),
              );
            }
            return ret;
          }),
          catchError((err) => {
            err.ref_s = true;
            return [new ActionFail({ result: err })];
          }),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  markAsPaidByXLS_TAXY$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkAsPaidByXLS_TAXY>(ShipmentActionTypes.MarkAsPaidByXLS_TAXY),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.markAsPaidByXLS_TAXY(payload.items).pipe(
          switchMap((res) => {
            const ret: Array<any> = [
              new ShipmentMassUpsert({ shipments: res.items }),
              new ActionSuccess({
                result: `Οι αποστολές ανανεώθηκαν επιτυχώς`,
              }),
              new ActionRefresh({ result: true }),
            ];
            if (res.eerror) {
              ret.push(new EError({ result: res.eerror }));
            }
            if (res.serror) {
              ret.push(new SError({ result: res.serror }));
            }
            if (res.cs > 0) {
              ret.push(
                new CSError({
                  result: `Βρέθηκαν ${res.cs} αποστολές με λάθος ποσό αντικαταβολής και διορθώθηκαν. Οι παρακάτω: ${res.cs_codes}`,
                }),
              );
            }
            return ret;
          }),
          catchError((err) => {
            err.ref_s = true;
            return [new ActionFail({ result: err })];
          }),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  markAsPaidByXLS_ACS$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkAsPaidByXLS_ACS>(ShipmentActionTypes.MarkAsPaidByXLS_ACS),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.markAsPaidByXLS_ACS(payload.items).pipe(
          switchMap((res) => {
            const ret: Array<any> = [
              new ShipmentMassUpsert({ shipments: res.items }),
              new ActionSuccess({
                result: `Οι αποστολές ανανεώθηκαν επιτυχώς`,
              }),
              new ActionRefresh({ result: true }),
            ];
            if (res.eerror) {
              ret.push(new EError({ result: res.eerror }));
            }
            if (res.serror) {
              ret.push(new SError({ result: res.serror }));
            }
            if (res.cs > 0) {
              ret.push(
                new CSError({
                  result: `Βρέθηκαν ${res.cs} αποστολές με λάθος ποσό αντικαταβολής και διορθώθηκαν. Οι παρακάτω: ${res.cs_codes}`,
                }),
              );
            }
            return ret;
          }),
          catchError((err) => {
            err.ref_s = true;
            return [new ActionFail({ result: err })];
          }),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  markAsPaidByXLS_CS$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkAsPaidByXLS_CS>(ShipmentActionTypes.MarkAsPaidByXLS_CS),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.markAsPaidByXLS_CS(payload.items).pipe(
          switchMap((res) => {
            const ret: Array<any> = [
              new ShipmentMassUpsert({ shipments: res.items }),
              new ActionSuccess({
                result: `Οι αποστολές ανανεώθηκαν επιτυχώς`,
              }),
              new ActionRefresh({ result: true }),
            ];
            if (res.eerror) {
              ret.push(new EError({ result: res.eerror }));
            }
            if (res.serror) {
              ret.push(new SError({ result: res.serror }));
            }
            if (res.cs > 0) {
              ret.push(
                new CSError({
                  result: `Βρέθηκαν ${res.cs} αποστολές με λάθος ποσό αντικαταβολής και διορθώθηκαν. Οι παρακάτω: ${res.cs_codes}`,
                }),
              );
            }
            return ret;
          }),
          catchError((err) => {
            err.ref_s = true;
            return [new ActionFail({ result: err })];
          }),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  markAsApprovedByXLS_GENIKI$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkAsApprovedByXLS_GENIKI>(
        ShipmentActionTypes.MarkAsApprovedByXLS_GENIKI,
      ),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.markAsApprovedByXLS_GENIKI(payload.items).pipe(
          switchMap((res) => {
            const ret: Array<any> = [
              new ShipmentMassUpsert({ shipments: res.items }),
              new ActionSuccess({
                result: `Οι αποστολές ανανεώθηκαν επιτυχώς`,
              }),
              new ActionRefresh({ result: true }),
            ];
            if (res.eerror) {
              ret.push(new EError({ result: res.eerror }));
            }
            if (res.serror) {
              ret.push(new SError({ result: res.serror }));
            }
            return ret;
          }),
          catchError((err) => {
            err.ref_s = true;
            return [new ActionFail({ result: err })];
          }),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  markAsPaidByXLS_GENIKI$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MarkAsPaidByXLS_GENIKI>(
        ShipmentActionTypes.MarkAsPaidByXLS_GENIKI,
      ),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.markAsPaidByXLS_GENIKI(payload.items).pipe(
          switchMap((res) => {
            const ret: Array<any> = [
              new ShipmentMassUpsert({ shipments: res.items }),
              new ActionSuccess({
                result: `Οι αποστολές ανανεώθηκαν επιτυχώς`,
              }),
              new ActionRefresh({ result: true }),
            ];
            if (res.eerror) {
              ret.push(new EError({ result: res.eerror }));
            }
            if (res.serror) {
              ret.push(new SError({ result: res.serror }));
            }
            if (res.cs > 0) {
              ret.push(
                new CSError({
                  result: `Βρέθηκαν ${res.cs} αποστολές με λάθος ποσό αντικαταβολής και διορθώθηκαν. Οι παρακάτω: ${res.cs_codes}`,
                }),
              );
            }
            return ret;
          }),
          catchError((err) => {
            err.ref_s = true;
            return [new ActionFail({ result: err })];
          }),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  checkKgByXLS$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CheckKgByXLS>(ShipmentActionTypes.CheckKgByXLS),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.checkKgByXLS(payload.items).pipe(
          switchMap((res) => {
            const ret: Array<any> = [
              new ShipmentMassUpsert({ shipments: res.items }),
              new ActionSuccess({ result: `Οι αποστολές ελέγθηκαν επιτυχώς` }),
              new ActionRefresh({ result: true }),
            ];
            if (res.eerror) {
              ret.push(new EError({ result: res.eerror }));
            }
            if (res.serror) {
              ret.push(new SError({ result: res.serror }));
            }
            if (res.cs > 0) {
              ret.push(
                new CSError({
                  result: `Βρέθηκαν ${res.cs} αποστολές με λάθος βάρος και διορθώθηκαν.`,
                }),
              );
            }
            return ret;
          }),
          catchError((err) => {
            err.ref_s = true;
            return [new ActionFail({ result: err })];
          }),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  checkKgByXLS_ACS$ = createEffect(() =>
    this.actions$.pipe(
      ofType<CheckKgByXLS_ACS>(ShipmentActionTypes.CheckKgByXLS_ACS),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.checkKgByXLS_ACS(payload.items).pipe(
          switchMap((res) => {
            const ret: Array<any> = [
              new ShipmentMassUpsert({ shipments: res.items }),
              new ActionSuccess({ result: `Οι αποστολές ελέγθηκαν επιτυχώς` }),
              new ActionRefresh({ result: true }),
            ];
            if (res.eerror) {
              ret.push(new EError({ result: res.eerror }));
            }
            if (res.serror) {
              ret.push(new SError({ result: res.serror }));
            }
            if (res.cs > 0) {
              ret.push(
                new CSError({
                  result: `Βρέθηκαν ${res.cs} αποστολές με λάθος βάρος και διορθώθηκαν.`,
                }),
              );
            }
            return ret;
          }),
          catchError((err) => {
            err.ref_s = true;
            return [new ActionFail({ result: err })];
          }),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  sendDeliveredXLS$ = createEffect(() =>
    this.actions$.pipe(
      ofType<SendDeliveredXLS>(ShipmentActionTypes.SendDeliveredXLS),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.sendDeliveredXLS(payload.items).pipe(
          switchMap((res) => {
            const ret: Array<any> = [
              new ShipmentMassUpsert({ shipments: res.items }),
              new ActionSuccess({
                result: `Οι αποστολές ανανεώθηκαν επιτυχώς`,
              }),
              new ActionRefresh({ result: true }),
            ];
            if (res.eerror) {
              ret.push(new EError({ result: res.eerror }));
            }
            if (res.serror) {
              ret.push(new SError({ result: res.serror }));
            }
            if (res.cs > 0) {
              ret.push(
                new CSError({
                  result: `Βρέθηκαν ${res.cs} αποστολές με λάθος δηλωθέν βάρος και διορθώθηκαν. Οι παρακάτω: ${res.cs_codes}`,
                }),
              );
            }
            return ret;
          }),
          catchError((err) => {
            err.ref_s = true;
            return [new ActionFail({ result: err })];
          }),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  returnDeliveredXLS$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnDeliveredXLS>(ShipmentActionTypes.ReturnDeliveredXLS),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.returnDeliveredXLS(payload.items).pipe(
          switchMap((res) => {
            const ret: Array<any> = [
              new ShipmentMassUpsert({ shipments: res.items }),
              new ActionSuccess({
                result: `Οι αποστολές ανανεώθηκαν επιτυχώς. Προστέθηκαν ${res.nr} επιστροφές.`,
              }),
              new ActionRefresh({ result: true }),
            ];
            if (res.eerror) {
              ret.push(new EError({ result: res.eerror }));
            }
            if (res.serror) {
              ret.push(new SError({ result: res.serror }));
            }
            if (res.aerror) {
              ret.push(new AError({ result: res.aerror }));
            }
            if (res.unferror) {
              ret.push(new UNFError({ result: res.unferror }));
            }
            return ret;
          }),
          catchError((err) => {
            err.ref_s = true;
            return [new ActionFail({ result: err })];
          }),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  ValidateSPDX$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ValidateSPDX>(ShipmentActionTypes.ValidateSPDX),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.validateSPDX(payload.items).pipe(
          switchMap((res) => {
            const ret: Array<any> = [
              new ShipmentMassUpsert({ shipments: res.items }),
              new ActionRefresh({ result: true }),
            ];
            if (res.eerror) {
              ret.push(new EError({ result: res.eerror }));
            }
            if (res.cs > 0) {
              ret.push(
                new ActionSuccess({
                  result: `Οι αποστολές ελέγχθηκαν επιτυχώς. Βρέθηκαν ${res.cs} αποστολές με διαφορές στο βάρος.`,
                }),
              );
            } else {
              ret.push(
                new ActionSuccess({
                  result: `Οι αποστολές ελέγχθηκαν επιτυχώς. Δεν βρέθηκαν αποστολές με διαφορές στο βάρος.`,
                }),
              );
            }
            return ret;
          }),
          catchError((err) => {
            err.ref_s = true;
            return [new ActionFail({ result: err })];
          }),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  AddByXLS$ = createEffect(() =>
    this.actions$.pipe(
      ofType<AddByXLS>(ShipmentActionTypes.AddByXLS),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.AddByXLS(payload.items).pipe(
          switchMap((res) => {
            const ret: Array<any> = [
              new ShipmentMassUpsert({ shipments: res.items }),
              //new ActionSuccess({ result : `Οι αποστολές ανανεώθηκαν επιτυχώς`}),
              // new ActionRefresh({ result : true})
            ];
            if (res.err && res.err.length > 0) {
              ret.push(new MAError({ result: res.err }));
            } else {
              ret.push(
                new ActionSuccess({
                  result: `Οι αποστολές ανανεώθηκαν επιτυχώς`,
                }),
              );
            }

            return ret;
          }),
          catchError((err) => {
            err.ref_s = true;
            return [new ActionFail({ result: err })];
          }),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  massMarkPaid$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MassMarkPaid>(ShipmentActionTypes.MassMarkPaid),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.massMarkPaid(payload.ids).pipe(
          switchMap((res) => [
            new ShipmentMassUpsert({ shipments: res.items }),
            new ActionSuccess({ result: `Οι αποστολές ανανεώθηκαν επιτυχώς` }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => {
            err.ref_s = true;
            return [new ActionFail({ result: err })];
          }),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  massMarkPaidApproved$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MassMarkPaidApproved>(ShipmentActionTypes.MassMarkPaidApproved),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.massMarkPaidApproved(payload.ids).pipe(
          switchMap((res) => [
            new ShipmentMassUpsert({ shipments: res.items }),
            new ActionSuccess({ result: `Οι αποστολές ανανεώθηκαν επιτυχώς` }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => {
            err.ref_s = true;
            return [new ActionFail({ result: err })];
          }),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  massMarkInvoiced$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MassMarkInvoiced>(ShipmentActionTypes.MassMarkInvoiced),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.massMarkInvoiced(payload.ids).pipe(
          switchMap((res) => [
            new ShipmentMassUpsert({ shipments: res.items }),
            new ActionSuccess({ result: `Οι αποστολές ανανεώθηκαν επιτυχώς` }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => {
            err.ref_s = true;
            return [new ActionFail({ result: err })];
          }),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  massMarkProcessed$ = createEffect(() =>
    this.actions$.pipe(
      ofType<MassMarkProcessed>(ShipmentActionTypes.MassMarkProcessed),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.massMarkProcessed(payload.ids).pipe(
          switchMap((res) => [
            new ShipmentMassUpsert({ shipments: res.items }),
            new ActionSuccess({ result: `Οι αποστολές ανανεώθηκαν επιτυχώς` }),
            new ActionRefresh({ result: true }),
          ]),
          catchError((err) => {
            err.ref_s = true;
            return [new ActionFail({ result: err })];
          }),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  checkWeight$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ShipmentCheckWeight>(ShipmentActionTypes.ShipmentCheckWeight),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.checkWeight(payload.shipment).pipe(
          switchMap((res) =>
            res.weight !== payload.shipment.weight
              ? [
                  new ShipmentUpsert({ shipment: res }),
                  new ActionSuccess({
                    result: `Η αποστολή ελέγχθηκε και το βάρος διορθώθηκε από  ${payload.shipment.weight}Kg σε ${res.weight}Kg. `,
                  }),
                ]
              : [
                  new ShipmentUpsert({ shipment: res }),
                  new ActionSuccess({
                    result: `Η αποστολή ελέγχθηκε και δεν βρέθηκαν διαφορές στο βάρος.`,
                  }),
                ],
          ),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  checkMassWeight$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ShipmentMassCheckWeight>(
        ShipmentActionTypes.ShipmentMassCheckWeight,
      ),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.checkMassWeight(payload.ids).pipe(
          switchMap((res) => {
            if (res.lerror) {
              return [
                new ShipmentMassUpsert({ shipments: res.items }),
                new ActionFail({
                  result: `Συνέβη ένα σφάλμα κατα τον έλεγχο των αποστολών. Ενδέχεται ορισμένες να μην έχουν διασταυρωθεί.`,
                }),
              ];
            } else {
              if (res.cc == 0) {
                return [
                  new ShipmentMassUpsert({ shipments: res.items }),
                  new ActionSuccess({
                    result: `Οι αποστολές διασταυρώθηκαν επιτυχώς αλλά δεν βρέθηκαν διαφορές στο βάρος.`,
                  }),
                ];
              } else {
                return [
                  new ShipmentMassUpsert({ shipments: res.items }),
                  new ActionSuccess({
                    result: `Οι αποστολές διασταυρώθηκαν επιτυχώς. Βρεθηκαν διαφορές στο βάρος σε ${res.cc} αποστολές.`,
                  }),
                ];
              }
            }
          }),
          catchError((err) => {
            err.ref_s = true;
            return [new ActionFail({ result: err })];
          }),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  approveShipment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ShipmentApprove>(ShipmentActionTypes.ShipmentApprove),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      concatMap((payload) =>
        this.ss.approveShipment(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new ActionSuccess({
              result: `Η αποστολή προς ${res.recipient_name} εγκρίθηκε επιτυχώς`,
            }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  fetchVoucher$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchVoucher>(ShipmentActionTypes.FetchVoucher),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showPageLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.fetchVoucher(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new GotVoucher(),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hidePageLoadingDistpatcher]),
    ),
  );

  updateShipment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ShipmentUpdate>(ShipmentActionTypes.ShipmentUpdate),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showActionLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.updateShipment(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new ActionSuccess({
              result: `Η αποστολή προς ${res.recipient_name} ενημερώθηκε επιτυχώς`,
            }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hideActionLoadingDistpatcher]),
    ),
  );

  fetchShipment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<FetchShipment>(ShipmentActionTypes.FetchShipment),
      map((action) => action.payload),
      switchMap((payload) =>
        this.ss.fetchShipment(payload.id).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new FetchedShipment(),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res]),
    ),
  );

  createShipment$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ShipmentCreate>(ShipmentActionTypes.ShipmentCreate),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showActionLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.createShipment(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new ActionSuccess({
              result: `Η αποστολή προς ${res.recipient_name} προστέθηκε επιτυχώς`,
            }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hideActionLoadingDistpatcher]),
    ),
  );

  createReturn$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ReturnCreate>(ShipmentActionTypes.ReturnCreate),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showActionLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.createReturn(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new ActionSuccess({ result: `Η επιστροφή προστέθηκε επιτυχώς` }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hideActionLoadingDistpatcher]),
    ),
  );

  manualCreateReturn$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ManualReturnCreate>(ShipmentActionTypes.ManualReturnCreate),
      map((action) => action.payload),
      tap(() => this.store.dispatch(this.showActionLoadingDistpatcher)),
      switchMap((payload) =>
        this.ss.manualCreateReturn(payload.shipment).pipe(
          switchMap((res) => [
            new ShipmentUpsert({ shipment: res }),
            new ActionSuccess({ result: `Η επιστροφή προστέθηκε επιτυχώς` }),
          ]),
          catchError((err) => [new ActionFail({ result: err })]),
        ),
      ),
      switchMap((res) => [res, this.hideActionLoadingDistpatcher]),
    ),
  );

  loadShipmentsPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ShipmentsPageRequested>(
        ShipmentActionTypes.ShipmentsPageRequested,
      ),
      mergeMap(({ payload }) => {
        this.store.dispatch(this.showPageLoadingDistpatcher);
        const requestToServer = this.ss.findShipments(payload.page);
        const lastQuery = of(payload.page);
        return forkJoin(requestToServer, lastQuery);
      }),
      map((response) => {
        const result: QueryResultsModel = response[0];
        const lastQuery: QueryParamsModel = response[1];
        return new ShipmentsPageLoaded({
          shipments: result.items,
          totalCount: result.totalCount,
          page: lastQuery,
        });
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private ss: ShipmentService,
    private store: Store<AppState>,
  ) {}
}
