<!-- begin:: Brand -->
<div
  class="kt-aside__brand kt-grid__item"
  [ngClass]="htmlClassService.getClasses('brand', true)"
  id="kt_aside_brand"
>
  <div class="kt-aside__brand-logo">
    <a href="javascript:;" routerLink="/">
      <img class="logo-img" alt="logo" src="/assets/media/logos/rex-logo-full-inverse.png" />
    </a>
  </div>
  <div class="kt-aside__brand-tools">
    <button
      ktToggle
      [options]="toggleOptions"
      class="kt-aside__brand-aside-toggler kt-aside__brand-aside-toggler--left"
      id="kt_aside_toggler"
    >
      <span>
        <svg class="oclose" xmlns="http://www.w3.org/2000/svg" height="24" width="24">
          <path d="M10 22 0 12 10 2l1.775 1.775L3.55 12l8.225 8.225Z" />
        </svg>
      </span>
      <span>
        <svg class="oclose" xmlns="http://www.w3.org/2000/svg" height="24" width="24">
          <path d="M8.025 22 6.25 20.225 14.475 12 6.25 3.775 8.025 2l10 10Z" />
        </svg>
      </span>
    </button>
  </div>
</div>
<!-- end:: Brand -->
