// Angular
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
// RxJS
import { finalize, take, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { select, Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, User } from '../../../../core/auth/';
import { Subject } from 'rxjs';
import { ConfirmPasswordValidator } from './confirm-password.validator';
import { selectGConfigById } from '../../../../core/rex';
import { LayoutUtilsService } from '../../../../core/_base/crud';

@Component({
  selector: 'kt-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegisterComponent implements OnInit, OnDestroy {
  registerForm: UntypedFormGroup;
  loading = false;
  errors: any = [];

  private unsubscribe: Subject<void>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

  /**
   * Component constructor
   *
   * @param authNoticeService: AuthNoticeService
   * @param translate: TranslateService
   * @param router: Router
   * @param auth: AuthService
   * @param store: Store<AppState>
   * @param fb: FormBuilder
   * @param layoutUtilsService
   * @param cdr
   */
  constructor(
    private authNoticeService: AuthNoticeService,
    private translate: TranslateService,
    private router: Router,
    private auth: AuthService,
    private store: Store<AppState>,
    private fb: UntypedFormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private cdr: ChangeDetectorRef,
  ) {
    this.unsubscribe = new Subject();
  }

  /*
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit() {
    this.initRegisterForm();
  }

  /*
   * On destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.loading = false;
  }

  /**
   * Form initalization
   * Default params, validators
   */
  initRegisterForm() {
    this.registerForm = this.fb.group(
      {
        firstname: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100),
          ]),
        ],
        lastname: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(3),
            Validators.maxLength(100),
          ]),
        ],
        email: [
          '',
          Validators.compose([
            Validators.required,
            Validators.email,
            Validators.minLength(3),
            // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
            Validators.maxLength(320),
          ]),
        ],
        password: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(100),
          ]),
        ],
        confirmPassword: [
          '',
          Validators.compose([
            Validators.required,
            Validators.minLength(6),
            Validators.maxLength(100),
          ]),
        ],
        is_company: [false],
        agree: [
          false,
          Validators.compose([Validators.required, Validators.requiredTrue]),
        ],
        gdpr: [
          false,
          Validators.compose([Validators.required, Validators.requiredTrue]),
        ],
      },
      {
        validator: ConfirmPasswordValidator.MatchPassword,
      },
    );
  }

  /**
   * Form Submit
   */
  submit() {
    const controls = this.registerForm.controls;

    // check form
    if (this.registerForm.invalid) {
      Object.keys(controls).forEach((controlName) =>
        controls[controlName].markAsTouched(),
      );
      return;
    }

    this.loading = true;

    if (!controls.agree.value) {
      this.authNoticeService.setNotice(
        'Πρέπει να αποδεχτείτε τους όρους χρήσης',
        'danger',
      );
      return;
    }

    const _user: User = new User();
    _user.clear();
    _user.email = controls.email.value;
    _user.firstname = controls.firstname.value;
    _user.lastname = controls.lastname.value;
    _user.new_pass = controls.password.value;
    _user.confirm_new_pass = controls.password.value;
    _user.is_company = controls.is_company.value;

    _user.roles = [];

    this.auth
      .register(_user)
      .pipe(
        tap(
          (user) => {
            if (user) {
              //this.store.dispatch(new Register({authToken: user.accessToken}));
              // pass notice message to the login page
              this.authNoticeService.setNotice(
                'Ο λογαριασμός σας δημιουργήθηκε αλλά θα παραμείνει ανενεργός μέχρι να τον ενεργοποιήσει ο διαχειριστής',
                'success',
              );
              this.router.navigateByUrl('/auth/login');
            }
          },
          (error) => {
            //console.log(error);
            if (error.error.email)
              this.authNoticeService.setNotice(
                this.translate.instant(
                  'Ένας λογαριασμός με αυτό το email υπάρχει ήδη.',
                ),
                'danger',
              );
            else
              this.authNoticeService.setNotice(
                this.translate.instant(
                  'Παρακαλώ βεβαιωθήτε πως τα δεδομένα που υποβάλατε είναι σωστά',
                ),
                'danger',
              );
          },
        ),
        takeUntil(this.unsubscribe),
        finalize(() => {
          this.loading = false;
          this.cdr.markForCheck();
        }),
      )
      .subscribe();
  }

  /**
   * Checking control validation
   *
   * @param controlName: string => Equals to formControlName
   * @param validationType: string => Equals to valitors name
   */
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.registerForm.controls[controlName];
    if (!control) {
      return false;
    }

    return (
      control.hasError(validationType) && (control.dirty || control.touched)
    );
  }

  ox() {
    const dt: { [key: string]: any } = {};
    dt['title'] = 'Όροι Χρήσης';
    dt['icon'] = 'fas fa-shield-alt';
    this.store
      .pipe(
        select(selectGConfigById('general__ox')),
        take(1),
        tap((res) => (dt['content'] = res.value)),
      )
      .subscribe();
    this.layoutUtilsService.viewInfo(dt);
  }

  gdpr() {
    const dt: { [key: string]: any } = {};
    dt['title'] = 'GDPR';
    dt['icon'] = 'fas fa-user-shield';
    this.store
      .pipe(
        select(selectGConfigById('general__gdpr')),
        take(1),
        tap((res) => (dt['content'] = res.value)),
      )
      .subscribe();
    this.layoutUtilsService.viewInfo(dt);
  }
}
