<mat-card class="pcard">
  <kt-alert
    *ngIf="sError | async as err"
    type="warn"
    [showCloseButton]="true"
    [duration]="10000"
    (close)="onAlertClose($event)"
  >
    {{ err }}
  </kt-alert>
  <h1 class="tt mbs">Προσθήκη API Key</h1>
  <div class="row">
    <div class="col-sm-8 col-md-6 col-lg-4 kt-margin-bottom-20-mobile">
      <mat-form-field class="mat-form-field-fluid">
        <input
          matInput
          placeholder="Όνομα Κλειδιού"
          class="right-align"
          [(ngModel)]="keyName"
          name="key_name"
        />
        <mat-hint align="start"
          >Παρακαλώ ειστάγετε το <strong>Όνομα</strong> του κλειδιού.
        </mat-hint>
      </mat-form-field>
    </div>
    <div class="col-2 kt-margin-bottom-20-mobile">
      <a
        href="javascript:;"
        color="primary"
        class="btn kt-margin-r-10"
        (click)="genKey()"
        mat-raised-button
        matTooltip="Δημιουργία Κλειδιού"
      >
        <i class="fas fa-plus"></i>
        <span>Προσθήκη</span>
      </a>
    </div>
    <div class="col-2 kt-margin-bottom-20-mobile">
      <a
        href="/media/api_manual.pdf"
        _target="blank"
        color="primary"
        class="btn btn-secondary kt-margin-r-10"
      >
        <i class="fas fa-book"></i>
        <span>Documentation</span>
      </a>
    </div>
    <div class="col-1 kt-margin-bottom-20-mobile">
      <mat-spinner *ngIf="isLoading" diameter="35"></mat-spinner>
    </div>
  </div>
</mat-card>
<mat-card *ngIf="ApiKeys.length > 0" class="pcard">
  <h1 class="tt mbs">Διαθέσιμα Κλειδιά</h1>
  <div *ngFor="let key of ApiKeys; let last = last" class="row mb-2 rp" [ngClass]="{ bb: !last }">
    <div class="col-3 align-self-center">
      <h3 class="ktitle bw m-0">{{ key.name }}</h3>
    </div>
    <div class="col-8 align-self-center">
      <p class="bw m-0">{{ key.api_key }}</p>
    </div>
    <div class="col-1 align-self-center">
      <button
        (click)="removeKey(key)"
        mat-icon-button
        color="warn"
        class="di"
        matTooltip="Διαγραφή"
      >
        <mat-icon>delete</mat-icon>
      </button>
    </div>
  </div>
</mat-card>
