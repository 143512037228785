// Angular
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
// RxJS
import { Observable, Subject, Subscription } from 'rxjs';
import { take, tap } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
// AppState
import { AppState } from '../../../../../../core/reducers';
import {
  LayoutUtilsService,
  MessageType,
} from '../../../../../../core/_base/crud';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import {
  GCActionFail,
  GCActionSuccess,
  GConfig,
  GConfigActionTypes,
  GConfigBulkUpdate,
  selectGConfigById,
} from '../../../../../../core/rex';

@Component({
  selector: 'kt-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss'],
})
export class InfoComponent implements OnInit, OnDestroy {
  @Input() saveEvent: Observable<void>;

  public Editor = ClassicEditor;

  contact: GConfig = new GConfig();
  about: GConfig = new GConfig();
  im: GConfig = new GConfig();

  tb = [
    'heading',
    '|',
    'bold',
    'italic',
    'link',
    'bulletedList',
    'numberedList',
    'blockQuote',
  ];

  serverError: Subject<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    private store: Store<AppState>,
    private layoutUtilsService: LayoutUtilsService,
    private actions$: Actions,
  ) {}

  ngOnInit() {
    this.serverError = new Subject();
    this.serverError.next(false);

    const ss = this.saveEvent.subscribe(() => this.save());
    this.subscriptions.push(ss);

    this.fetchDataFromStore();

    const sss = this.actions$
      .pipe(
        ofType<GCActionSuccess>(GConfigActionTypes.GCActionSuccess),
        tap(({ payload }) => {
          this.layoutUtilsService.showActionNotification(
            payload.result,
            MessageType.Update,
            5000,
            true,
            true,
          );
        }),
      )
      .subscribe();
    this.subscriptions.push(sss);

    const sf = this.actions$
      .pipe(
        ofType<GCActionFail>(GConfigActionTypes.GCActionFail),
        tap(({ payload }) => {
          if (
            // eslint-disable-next-line no-prototype-builtins
            payload.result.hasOwnProperty('error') &&
            // eslint-disable-next-line no-prototype-builtins
            payload.result.error.hasOwnProperty('detail')
          ) {
            this.serverError.next(payload.result.error.detail);
          } else {
            this.serverError.next(
              'Συνέβη ένα σφάλμα με την αποθήκευση των δεδομένων. Παρακαλώ ξαναπροσπαθήστε.',
            );
          }
        }),
      )
      .subscribe();
    this.subscriptions.push(sf);
  }

  fetchDataFromStore() {
    this.store
      .pipe(
        select(selectGConfigById('general__contact')),
        take(1),
        tap((res) => (this.contact = { ...res })),
      )
      .subscribe();

    this.store
      .pipe(
        select(selectGConfigById('general__about')),
        take(1),
        tap((res) => (this.about = { ...res })),
      )
      .subscribe();

    this.store
      .pipe(
        select(selectGConfigById('authenticated__im')),
        take(1),
        tap((res) => (this.im = { ...res })),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  save() {
    /* const tmp : GConfig = new GConfig()
        tmp.identifier = this.gdpr.identifier;
        tmp.value = this.gdpr.value;
        this.store.dispatch( new GConfigUpdate( { gconfig : tmp } ));*/
    const buo: { [key: string]: any } = {};
    buo[this.contact.identifier] = this.contact.value;
    buo[this.about.identifier] = this.about.value;
    buo[this.im.identifier] = this.im.value;
    this.store.dispatch(new GConfigBulkUpdate({ values: buo }));
  }

  onAlertClose($event) {
    this.serverError.next(false);
  }
}
