import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MatBottomSheetRef,
  MAT_BOTTOM_SHEET_DATA,
} from '@angular/material/bottom-sheet';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { ChargeAnalysisComponent } from '../charge-analysis/charge-analysis.component';
import {
  map,
  catchError,
  mergeMap,
  finalize,
  takeUntil,
  takeWhile,
  filter,
  debounceTime,
  distinctUntilChanged,
  tap,
  skip,
  take,
  delay,
} from 'rxjs/operators';
import { fromEvent, merge, Observable, of, Subscription } from 'rxjs';
import { ShipmentService } from '../../../../core/rex/_services/shipment.service';
import { Status } from '../../../../core/rex/_models/status.model';

@Component({
  selector: 'kt-shipment-view-bs',
  templateUrl: './shipment-view-bs.component.html',
  styleUrls: ['./shipment-view-bs.component.scss'],
})
export class ShipmentViewBsComponent implements OnInit {
  _cs = false;
  _status: Observable<Status[]>;

  constructor(
    public bsRef: MatBottomSheetRef<ShipmentViewBsComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any,
    private ss: ShipmentService,
  ) {}

  ngOnInit() {
    if (!this.data.financial) {
      this._cs = this.data.item.courier == 'COURIER_CENTER';
      this._status = this.ss.getStatus(this.data.item);
    }
  }

  close() {
    this.bsRef.dismiss();
  }
}
