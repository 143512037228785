import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { AppState } from '../../../../../../core/reducers';
import { groupBy, mapValues, omit, sortBy } from 'lodash';
import {
  LayoutUtilsService,
  MessageType,
} from '../../../../../../core/_base/crud';
import { User } from '../../../../../../core/auth';

import {
  CActionFail,
  CActionSuccess,
  Charge,
  ChargeActionTypes,
  ChargesAdd,
  selectChargesByUid,
} from '../../../../../../core/rex';

import createNumberMask from 'text-mask-addons/dist/createNumberMask';

@Component({
  selector: 'kt-charges',
  templateUrl: './charges.component.html',
  styleUrls: ['./charges.component.scss'],
})
export class ChargesComponent implements OnInit, OnDestroy {
  @Input() user: User;
  @Input() chargeSettingsSubject: BehaviorSubject<any>;

  charges$: Observable<any>;

  new_charges: Array<Charge> = [];
  chargeForm: UntypedFormGroup;

  curr_mask = createNumberMask({
    prefix: '€ ',
    allowDecimal: true,
    decimalLimit: 2,
    //requireDecimal: true
  });

  kg_mask = createNumberMask({
    suffix: ' Kg',
    prefix: '',
    allowDecimal: true,
    decimalLimit: 1,
    //requireDecimal: true
  });

  per_mask = createNumberMask({
    suffix: ' %',
    prefix: '',
    allowDecimal: true,
    decimalLimit: 2,
    //requireDecimal: true
  });

  min = 0;
  max = 150;
  wev = 0.1;

  serverError: Subject<any>;

  private subscriptions: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private chargeFB: UntypedFormBuilder,
    private store: Store<AppState>,
    private actions$: Actions,
    private ref: ChangeDetectorRef,
    private layoutUtilsService: LayoutUtilsService,
  ) {}

  ngOnInit() {
    //console.log(this.chargeSettingsSubject.value);
    this.serverError = new Subject();

    const ss = this.actions$
      .pipe(
        ofType<CActionSuccess>(ChargeActionTypes.CActionSuccess),
        tap(({ payload }) => {
          this.layoutUtilsService.showActionNotification(
            payload.result,
            MessageType.Update,
            5000,
            true,
            true,
          );
          this.resetCharges();
        }),
      )
      .subscribe();
    this.subscriptions.push(ss);

    const sf = this.actions$
      .pipe(
        ofType<CActionFail>(ChargeActionTypes.CActionFail),
        tap(({ payload }) => {
          if (
            // eslint-disable-next-line no-prototype-builtins
            payload.result.hasOwnProperty('error') &&
            // eslint-disable-next-line no-prototype-builtins
            payload.result.error.hasOwnProperty('detail')
          ) {
            this.serverError.next(payload.result.error.detail);
          } else {
            this.serverError.next(
              'Συνέβη ένα σφάλμα με την αποθήκευση των δεδομένων. Παρακαλώ ξαναπροσπαθήστε.',
            );
          }
        }),
      )
      .subscribe();
    this.subscriptions.push(sf);

    this.charges$ = this.store.pipe(
      select(selectChargesByUid(this.user.id)),
      //tap( res => this.uCharges = res ),
      map((res) => {
        return mapValues(
          groupBy(
            sortBy(res, (ach) => ach.date_created).reverse(),
            'date_created',
          ),
          (clist) => clist.map((ch) => omit(ch, 'date_created')),
        );
      }),
    );

    /*    let nc : Charge = new Charge();
        nc.clear();
        nc.weight_start = 0.0;
        this.new_charges.push(nc);
    */
    this.createForm();
  }

  createForm() {
    const fgd: { [k: string]: any } = {
      static_charge: [''],
      per_kg_charge: [''],
      cod_charge: [''],
      weight_start: [''],
      weight_end: [''],
    };

    this.chargeForm = this.chargeFB.group(fgd);
    const k = (this.max - this.min) / 2 + this.min;
    this.chargeForm.get('weight_end').setValue(k.toFixed(0));
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  addCharge() {
    const nc: Charge = new Charge();

    if (this.new_charges.length == 0) {
      nc.weight_start = 0;
    } else {
      nc.weight_start = this.min;
      nc.weight_end = parseFloat(this.chargeForm.controls.weight_end.value);
      this.min = nc.weight_end;
      this.new_charges[0].weight_start = this.min;
    }

    if (this.chargeForm.controls.static_charge.value) {
      nc.static_charge = parseFloat(
        this.chargeForm.controls.static_charge.value
          .replace('€ ', '')
          .replace(',', ''),
      );
    }
    if (this.chargeForm.controls.per_kg_charge.value) {
      nc.per_kg_charge = parseFloat(
        this.chargeForm.controls.per_kg_charge.value
          .replace('€ ', '')
          .replace(',', ''),
      );
    }
    if (this.chargeForm.controls.cod_charge.value) {
      nc.cod_charge = parseFloat(
        this.chargeForm.controls.cod_charge.value
          .replace('€ ', '')
          .replace(',', ''),
      );
    }

    nc.user = this.user.id;

    this.new_charges.push(nc);
    //Change array ref to triger onChanges in child comp.
    this.new_charges = this.new_charges.slice();

    this.createForm();
  }

  resetCharges() {
    this.new_charges = new Array<Charge>();
    this.min = 0;
    this.serverError.next(false);
  }

  saveCharges() {
    this.serverError.next(false);
    this.store.dispatch(new ChargesAdd({ charges: this.new_charges }));
  }

  nullSort() {}

  onAlertClose($event) {
    this.serverError.next(false);
  }
}
