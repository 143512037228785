// Angular
import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
// RxJS
import { BehaviorSubject, fromEvent } from 'rxjs';
// NGRX
import { Store } from '@ngrx/store';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
// Auth
import { ExtraCharge } from '../../../../../../core/rex';
// State
import { AppState } from '../../../../../../core/reducers';
// Layout
import { LayoutUtilsService } from '../../../../../../core/_base/crud';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

@Component({
  selector: 'kt-extra-charges',
  templateUrl: './extra-charges.component.html',
  styleUrls: ['./extra-charges.component.scss'],
})
export class ExtraChargesComponent implements OnInit {
  @Input() extraChargesSubject: BehaviorSubject<ExtraCharge[]>;
  displayedColumns = ['reason', 'description', 'ammount', 'actions'];
  cta: ExtraCharge;

  curr_mask = createNumberMask({
    suffix: ' €',
    prefix: '',
    allowDecimal: true,
    decimalLimit: 2,
    //requireDecimal: true
    allowNegative: true,
  });

  constructor(
    private fb: UntypedFormBuilder,
    private store: Store<AppState>,
    private layoutUtilsService: LayoutUtilsService,
  ) {}

  ngOnInit() {
    this.cta = new ExtraCharge();
    this.cta.clear();
  }

  add() {
    const ncta = new ExtraCharge();
    ncta.ammount = parseFloat(
      ('' + this.cta.ammount).replace(' €', '').replace(',', ''),
    );
    ncta.iid = this.makeid(10);
    ncta.description = this.cta.description;
    ncta.reason = this.cta.reason;
    this.extraChargesSubject.next([...this.extraChargesSubject.value, ncta]);
    this.cta.clear();
  }

  removeEC(c: any) {
    if (c.id) {
      this.extraChargesSubject.next(
        this.extraChargesSubject.value.filter((k: any) => k.id !== c.id),
      );
    } else {
      this.extraChargesSubject.next(
        this.extraChargesSubject.value.filter((k: any) => k.iid !== c.iid),
      );
    }
  }

  makeid(length) {
    let result = '';
    const characters =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
