import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject, BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { mergeMap, takeLast, last, map, tap, delay } from 'rxjs/operators';
import { Store, select } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { AppState } from '../../../../../../core/reducers';

import { User } from '../../../../../../core/auth';

import {
  Userfile,
  UserfileActionTypes,
  UserfileCreate,
  FileUploadProgress,
  UploadSuccess,
  UploadFailure,
} from '../../../../../../core/rex';

@Component({
  selector: 'kt-usr-files',
  templateUrl: './usr-files.component.html',
  styleUrls: ['./usr-files.component.scss'],
})
export class UsrFilesComponent implements OnInit, OnDestroy {
  @Input() user: User;
  files: File[] = [];
  dragFiles: any;
  validComboDrag: any;
  invalidComboDrag: any;
  lastInvalids: any;
  progress: { [key: string]: BehaviorSubject<number> } = {};
  failed: Array<string> = [];
  fbs: BehaviorSubject<Array<string>> = new BehaviorSubject<Array<string>>(
    this.failed,
  );

  private subscriptions: Subscription[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private fileFB: UntypedFormBuilder,
    private store: Store<AppState>,
    private actions$: Actions,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    const up = this.actions$
      .pipe(
        ofType<FileUploadProgress>(UserfileActionTypes.FileUploadProgress),
        tap(({ payload }) => {
          this.progress[payload.userfile.name].next(payload.progress);
        }),
      )
      .subscribe();
    this.subscriptions.push(up);

    const us = this.actions$
      .pipe(
        ofType<UploadSuccess>(UserfileActionTypes.UploadSuccess),
        tap(({ payload }) => {
          delete this.progress[payload.userfile.name];
          this.files = this.files.filter(
            (f) =>
              !(
                f.name === payload.userfile.name &&
                f.size === payload.userfile.size
              ),
          );
          this.ref.detectChanges();
        }),
      )
      .subscribe();
    this.subscriptions.push(us);

    const uf = this.actions$
      .pipe(
        ofType<UploadFailure>(UserfileActionTypes.UploadFailure),
        tap(({ payload }) => {
          delete this.progress[payload.userfile.name];
          this.failed.push(payload.userfile.name);
          console.log(this.failed);
          this.fbs.next(this.failed);
        }),
      )
      .subscribe();
    this.subscriptions.push(uf);
  }

  ngOnDestroy() {
    this.subscriptions.forEach((sb) => sb.unsubscribe());
  }

  uploadAll() {
    this.failed.splice(0, this.failed.length);
    this.fbs.next(this.failed);
    this.files.forEach((f, index) => {
      this.progress[f.name] = new BehaviorSubject<number>(0);
      this.store.dispatch(
        new UserfileCreate({ userfile: f, uid: this.user.id }),
      );
    });
  }

  remove(i: number) {
    this.files.splice(i, 1);
  }
}
